/*
 * Util method to validate objects, array etc.
 */
/* eslint-disable no-console */

// This Speed up calls to hasOwnProperty
const doesOwnProperty = Object.prototype.hasOwnProperty;

/**
 * This Util used to check if object has valid propertyKey
 * @param {object} obj
 * @param {string} propertyKey
 * @returns {boolean}
 */
export const isObject = (obj, propertyKey) => {
  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (doesOwnProperty.call(obj, propertyKey)) return true;
};

export const isObjEmpty = obj => {
  return JSON.stringify(obj) === "{}";
};

/**
 * This Util used to check if key exist in object
 * @param {object} obj
 * @param {string} key
 * @returns {boolean}
 */
export const doesKeyExist = (obj, key) => {
  return obj.hasOwnProperty(key);
};
/**
 *
 * @param {object} obj
 * @returns {boolean} returns true if object or array is not empty
 */
export const isEmpty = obj => {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (doesOwnProperty.call(obj, key)) return false;
  }
  return true;
};

/**
 * doesEmpty() returns The value is deemed to be empty if it is either:
 *
 * - `null`
 * - `undefined`
 * - a zero-length array
 * - a zero-length string (Unless the `allowEmptyString` parameter is set to `true`)
 *
 * @param {object} value The value to test
 * @param {boolean} allowEmptyString (optional) true to allow empty strings (defaults to false)
 * @return {boolean} Returns true if the passed value is empty, false otherwise
 */
export const doesEmpty = (value, allowEmptyString) => {
  return (
    value === null ||
    value === undefined ||
    (!allowEmptyString ? value === "" : false) ||
    (isArrayExist(value) && value.length === 0)
  );
};

/**
 *
 * @param {object} value Params can be object or array
 * @return {boolean} returns true if the passed value is a JavaScript Array, false otherwise
 */
export const isArrayExist = value => {
  return Object.prototype.toString.call(value) === "[object Array]";
};
/**
 *
 * @param {object} list
 * @return {boolean} true or false
 */
export const isArrayList = list => {
  return Array.isArray(list);
};

/**
 * This Util checks if object not null and not array
 * @param {string} val
 * @returns {boolean}
 */
export const isObjectOnly = val => {
  return typeof val === "object" && !Array.isArray(val) && val !== null;
};
/**
 *
 * @param {object} obj
 * @return {string} json string
 */
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}
/**
 * @param  {object} source
 * @param  {object} template
 * @param  {boolean} alreadyCloned
 */
export function deepCopyFromTemplate(source, template, alreadyCloned) {
  const cloneObject = alreadyCloned ? template : Object.assign({}, template);
  const keys = Object.keys(cloneObject);
  keys.forEach(key => {
    const defaultValue = cloneObject[key];
    const value = source[key];
    cloneObject[key] = value === undefined ? defaultValue : value;
  });
  return cloneObject;
}
/**
 *
 * @param {object} data
 * @returns {string} converts object to json string
 */
export const objectStringify = data => {
  /*
  const myObj = {
    name: "Skip",
    age: 2,
    favoriteFood: "Steak"
  };
  console.log(JSON.stringify(myObj));
  */
  const result = JSON.stringify(data);
  return result;
};
/**
 *
 * @param {object} object
 * @returns {number}
 */
export const boolToInt = object => {
  const newObject = {};
  /* eslint-disable array-callback-return */
  Object.keys(object).map(x => {
    newObject[x] = object[x] === true ? 1 : object[x] === false ? 0 : object[x];
  });
  /* eslint-enable array-callback-return */
  return newObject;
};
/**
 *
 * @param {object} obj1
 * @param {object} obj2
 * @returns {boolean} compares two objects and returns true or false
 */
export const compareObject = (obj1, obj2) => {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== "object") {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach(key => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
        const value = compareObject(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
};
/**
 *
 * @param {object} obj
 * @returns {array}
 */
export const objectToArrayOfLabelValue = obj => {
  const arr = [];
  for (const key in obj) {
    arr.push({ value: key, label: obj[key] });
  }
  return arr;
};

/**
 * This func() filters the given "key" that should be deleted from Object.
 * @param {object} initObj
 * @param {string} deleteKey
 * @returns a new object from the remaining keys and the initial object
 */
export function removeByKey(initObj, deleteKey) {
  return Object.keys(initObj)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = initObj[current];
      return result;
    }, {});
}

/**
 * This util filters array of items based on matchValue; return only single record from list
 * @param {array} list
 * @param {string} key
 * @param {string} match
 * @returns {boolean}
 */
export function findRecord(list, key, match) {
  let cloneObj = {};
  // returns array of matched items
  const found = list.filter(function(obj) {
    return obj[key] === match;
  });

  if (found.length === 1) {
    cloneObj = Object.assign({}, found[0]);
  }
  return cloneObj;
}
/**
 *
 * @param {array} makelist
 * @param {string} make
 * @returns object matched from array of objects
 */
export function findVariant(makelist, make) {
  const matches = makelist.filter(m => {
    return m.make === make;
  });
  return matches.length !== 0 ? matches[0].variant : "";
}
/**
 *
 * @param {array} makelist
 * @param {string} make
 * @returns
 */
export function getMakeFromDealerCatalogs(makelist, make) {
  const matches = makelist.filter(m => {
    return m.make === make;
  });
  return matches.length !== 0 ? matches[0] : null;
}
/**
 * This util to find dealercatalog record which has timeBasedMenus = 1 or cappedPricing = 1
 * @param {array} makelist
 * @param {string} make
 * @returns {boolean}
 */
export function hasCappedOrTimeBasedMenu(makelist, make) {
  const matches = makelist.filter(m => {
    return m.make === make && (m.cappedPricing === 1 || m.timeBasedMenus === 1);
  });
  return matches.length !== 0;
}
/**
 *
 * @param {array} makelist
 * @param {string} make
 * @returns {boolean}
 */
export function hasCappedPricing(makelist, make) {
  const matches = makelist.filter(m => {
    return (
      m.make === make &&
      m.hasOwnProperty("cappedPricing") &&
      m.cappedPricing === 1
    );
  });
  return matches.length !== 0;
}
/**
 * This Util Filters array of items based on query; return filtered list[]
 * @param {array} data
 * @param {string} key
 * @param {string} query
 * @returns {array} filtered object array
 * Eg:
    const heroes = [
      {name: "Batman", franchise: "DC"},
      {name: "Ironman", franchise: "Marvel"},
      {name: "Thor", franchise: "Marvel"},
      {name: "Superman", franchise: "DC"}
    ];
    const marvelHeroes =  heroes.filter(function(hero) {
      return hero.franchise == "Marvel";
    });
 */
export function filterRecords(data, key, query) {
  const filteredList = data.filter(function(obj) {
    return obj[key] === query;
  });
  return filteredList;
}

/**
 * This Util Filters array of items based on search criteria (query)
 * @param {array} data
 * @param {string} key
 * @param {string} query
 * @returns {array} filtered array
 */
export function filterArrayObjects(data, key, query) {
  const final = data.filter(op => {
    return op[key].toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });
  return final;
}
/**
 * This util Iterate each property and check for a null value - then reset
 * to empty string if null is found - iterate recursivly for objects
 * @param {array} values
 * @returns {array}
 */
export const encodeNullValues = values => {
  Object.keys(values).forEach(key => {
    const value = values[key];
    if (value === null || value === undefined) {
      values[key] = "";
    } else if (typeof value === "number") {
      values[key] = value.toString();
    } else if (isObject(value)) {
      values[key] = encodeNullValues(value);
    }
  });
  return values;
};
/**
 *
 * @param {string} laborTime
 * @returns {float}
 */
export function convertFromMinutesToHours(laborTime) {
  if (!doesEmpty(laborTime)) {
    if (typeof laborTime === "number") {
      const v = laborTime / 60.0;
      return Math.round(10 * v) / 10;
    } else if (typeof laborTime === "string") {
      const v = parseFloat(laborTime) / 60.0;
      return Math.round(10 * v) / 10;
    }
  }
  return laborTime;
}
/**
 *
 * @param {array} values
 * @returns {array}
 */
export function convertBooleanToBinary(values) {
  const keys = Object.keys(values);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (typeof values[key] === "boolean") {
      values[key] = values[key] | 0;
    }
  }
  return values;
}

/**
 * This Util used to check for pricing workflow
 * @param {array} makelist
 * @param {string} make
 * @returns
 */
export function isCalculatedPricing(makelist, make) {
  if (make) {
    const matches = makelist.filter(m => {
      return m.make === make && m.pricingMethod === 1;
    });
    console.log("isCalculatedPricing called for", make, matches);
    return matches.length === 1 ? true : false;
  }
}
/**
 *
 * @param {array} makelist
 * @param {string} make
 * @returns {boolean} true or false
 */
export function isNoPricing(makelist, make) {
  if (make) {
    const matches = makelist.filter(m => {
      return m.make === make && m.pricingMethod === 0;
    });
    console.log("isNoPricing called for", make, matches);
    return matches.length === 1 ? true : false;
  }
}
/**
 *
 * @param {object} localeStringsObj
 * @returns {object}
 */
export function convertObjectValuesToUppercase(localeStringsObj) {
  const uppercasesLocaleStrings = {};
  Object.keys(localeStringsObj).forEach(key => {
    uppercasesLocaleStrings[key] = "*"
      // .concat(localeStringsObj[key].toUpperCase())
      .concat(key)
      .concat("*");
  });
  return uppercasesLocaleStrings;
}
/* eslint-enable no-console */
