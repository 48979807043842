/* eslint-disable no-console */
/* TODO - use transformer for request and response data to parse and bind to UI */
import axios from "axios";
import jwtDecode from "jwt-decode";
import * as gtag from "../components/utils/gtag-manager";
import { httpCodes } from "../components/utils/error-codes";
import {
  xlate,
  handleFatalApplicationAccess
} from "../commonUtil/i18n/locales";
// import uiConfig from "../../public/data/UIConfig.json";
import uiConfig from "../commonUtil/config/UIConfig.json";

// const MAX_REFRESH_CALLS = 3;
const DealerCheck = "/ops/dealerapi/settings";
// const UserCheck = "/ops/userapi/getUserDetails";

export let baseUrl = window.location.origin;
export let baseLoginUrl = "";
export let baseImgUrl = "";
const hostname = window.location.hostname;
const tokens = hostname.split(".");
export let namespace = tokens[1];
export let servicestudioUrl = "";

if (hostname.indexOf("catalogadmin.xtime.com") !== -1) {
  namespace = "prod";
} else if (hostname.indexOf("catalogadmin.xtime.net.au") !== -1) {
  namespace = "prodaus";
} else if (hostname.indexOf("localhost") !== -1) {
  // default localhost to dev5
  namespace = "dev5";
}

// look up URLs from UIConfig.json
baseUrl = uiConfig[namespace].catalogAdminManagerUrl;
baseLoginUrl = uiConfig[namespace].dealerPortalLoginUrl;
baseImgUrl = uiConfig[namespace].catalogImageUrl;
servicestudioUrl = uiConfig[namespace].servicestudioUrl;
export const catalogPortalUrl = uiConfig[namespace].catalogPortalUrl;
export const commonopsDbEnabled = uiConfig[namespace].commonopsDbEnabled;

export const islocalhost = hostname.indexOf("localhost") !== -1;
// if (islocalhost) {
//  // baseUrl = "http://localhost.charlesproxy.com:8080";
//  baseUrl = "http://localhost:8080";
// }

export let accessToken = null;
export const DEFAULT_LOCALE = "en_US";
const NETWORK_ERROR = "Network Error";

export function setAccessToken(token) {
  accessToken = token;
}

// if any rest api fails, redirect to default page - https://catalog.devxmm.xtimeappsnp.xtime.com
export function redirectToDefaultPage() {
  document.location.replace(baseLoginUrl);
  // const env = "x5dev5";
  // let domain = document.location.hostname.split(".")[0];
  // if (process.env.NODE_ENV !== "production") {
  //   domain = env;
  // }

  // const maintenanceUrl = `//${domain}.xtime.com/`;
  // console.log(maintenanceUrl);
  // document.location.replace(maintenanceUrl);
}

/* Create instance of axios to support rest endpoint
https://opsadminmanager.devxmm.nonprod.west2.xtimeappsnp.com/
*/
const xmmAxios = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// Global axios defaults
xmmAxios.defaults.headers.post["Content-Type"] = "application/json";
// Now all requests using this instance will wait 2.5 seconds before timing out
// xmmAxios.defaults.timeout = 5000;
// xmmAxios.defaults.crossDomain = true;

// instance.interceptors.request...
// Add a request interceptor
xmmAxios.interceptors.request.use(
  request => {
    request.startTime = new Date();
    // console.log("xmmAxios request", request);
    // Edit request config
    return request;
  },
  error => {
    // console.log(error);
    return Promise.reject(error);
  }
);

// function getLocaleString() {
//   let locale = localStorage.getItem("locale");
//   if (!locale) {
//     locale = DEFAULT_LOCALE;
//   }
//   return locale;
// }
/* set locole in localStorage */
export function setLocale(locale) {
  localStorage.setItem("locale", locale);
}

// Note: If we want to remove interceptor
// xmmAxios.interceptors.request.eject(reqInterceptor);

/* Method return service URL */
function getURLOnly(str) {
  // return str.split("xtime.com/")[1];
  return str.split("?")[0];
}

// Add a response interceptor- to handle response and error objects
xmmAxios.interceptors.response.use(
  response => {
    logResponseTime(response.config, response);

    // TODO - we can transform raw response to common format, only when each rest API return data{}
    const { status } = response;
    if (status === 200 || status === 201) {
      // console.log("xmmAxios.interceptors.response.status", status);
      return response;
    } else {
      hideBodyMask(); // generic case - remove masking for any failure calls
      trackErrorCode(response);
      throw new Error(response);
    }
  },
  error => {
    // response error handling
    // console.log("xmmAxios error", error);
    if (error.response && error.response.status) {
      const { status } = error.response;
      trackErrorCode(error.response);
      if (status === 503) {
        redirectToDefaultPage();
      } else if (status === 401 || status === 404) {
        return error.response;
      }
      // console.error(`%c Server ERROR: ${error}`, "background: #f00; color: #fff");
      hideBodyMask(); // generic case - remove masking for any failure calls
    }
    throw error;
  }
);

/* Track rest API response time in GA  */
function logResponseTime(request, response) {
  let serviceName = "";
  const start = request.startTime;
  const end = new Date().getTime();
  const loadTime = end - start.getTime();
  if (request) {
    serviceName = getURLOnly(request.url);
    const logObj = {
      method: request.method,
      status: response.status,
      serviceName,
      loadTime,
      requestPayload: createRequestPayload(request)
    };

    // console.log("xmmAxios logResponseTime ", request, response);
    gtag.filterServiceName(logObj, response);
  }
}
function createRequestPayload(request) {
  let payload = "";
  if (request.method === "get") {
    payload = !request.params ? "" : JSON.parse(JSON.stringify(request.params));
  } else if (request.method === "post") {
    payload = !request.data ? "" : JSON.parse(JSON.stringify(request.data));
  }
  return payload;
}

/* util to capture Http Errors in GA */
function trackErrorCode(response) {
  const error = httpCodes[response.status];
  const logObj = {
    eventName: "restException",
    method: response.config.method,
    statusCode: response.status,
    message: !error ? "UNKNOWN Error" : error,
    serviceName: getURLOnly(response.config.url)
  };
  gtag.trackError(logObj, response);
}
export function jwtXsession(callback, errorHandler) {
  // console.log("jwtXsession");
  // todo: need to test width Dave Anderson's change
  let accessToken = localStorage.getItem("accessToken");
  let refreshToken = localStorage.getItem("refreshToken");
  // const hostname = window.location.hostname;
  if (hostname.indexOf("localhost") === -1) {
    if (accessToken == null || refreshToken == null) {
      // detect pending xsession calls
      const pendingXsessionCalls = localStorage.getItem("pendingXsessionCalls");
      if (!pendingXsessionCalls || pendingXsessionCalls.length === 0) {
        localStorage.setItem("pendingXsessionCalls", []);
      } else {
        // push pending xsession calls to the queue
        pendingXsessionCalls.push({ callback, errorHandler });
        return;
      }
      // need to get accessToken
      // console.log("accessToken/refreshToken are null", accessToken,refreshToken );
      axios
        .post(`${baseLoginUrl}/rest/login/jwt/xsession`, null, {
          withCredentials: true
        })
        .then(response => {
          // console.log("jwtSession.response", response);
          if (
            response.status === 200 &&
            response.data &&
            // response.data.success &&
            response.data.data
          ) {
            accessToken = response.data.data.accessToken;
            refreshToken = response.data.data.refreshToken;
            const timeOffsetInSecs = localStorage.getItem("timeOffsetInSecs");
            if (!timeOffsetInSecs) {
              const decodedAccessToken = jwtDecode(accessToken);
              const currentTimeInSecs = Math.floor(new Date().getTime() / 1000);
              const timeDiffInSecs = decodedAccessToken.iat - currentTimeInSecs;
              localStorage.setItem("timeOffsetInSecs", timeDiffInSecs);
              console.log("timeOffsetInSecs", timeDiffInSecs);
            }
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            callback(response.data.data);
            const pendingXsessionCalls = localStorage.getItem(
              "pendingXsessionCalls"
            );
            if (pendingXsessionCalls) {
              console.log("pendingXsessionCalls", pendingXsessionCalls);
              pendingXsessionCalls.forEach(apiCall => {
                // call
                apiCall.callback(response.data.data);
              });
              localStorage.removeItem("pendingXsessionCalls");
            }
          } else {
            handleSessionExpiration(response.data);
          }
        })
        .catch(error => {
          // handle errors in common place
          // console.log("error", error);
          if (errorHandler) {
            const message =
              !error || error.message === NETWORK_ERROR
                ? xlate("xmm.portal.network.error")
                : error.message;
            errorHandler({
              success: false,
              message
            });
          }
        });
    } else {
      // accessToken and refreshToken exist
      // const decoded = jwtDecode(accessToken);
      // console.log(decoded);
      callback({ accessToken });
    }
  } else {
    // localhost
    callback();
  }
}

const testEmail = "dcklee1668@gmail.com";

export function authorize(token, callback, errorHandler) {
  if (accessToken) {
    return;
  }
  setAccessToken(token);
  if (islocalhost || !commonopsDbEnabled) {
    // Local Dev Testing to bypass authorize
    let email = testEmail;
    if (token) {
      const decodedAccessToken = jwtDecode(accessToken);
      if (decodedAccessToken) {
        email = decodedAccessToken.sub;
        console.log("decodedAccessToken", decodedAccessToken);
      }
    }
    const role = email === testEmail ? "metavehicle" : "preview";
    callback({
      // eslint-disable-next-line camelcase
      user_name: email,
      role,
      email,
      isxmmadmin: false
    });
    return;
  }
  // PROD use only
  makeSecureRestApi(
    {
      url: "/opsadmin/security/authorize",
      method: "get"
    },
    response => {
      console.log("authenticate", response);
      callback(response);
    },
    error => {
      console.log(error);
      if (errorHandler) {
        errorHandler(error);
      }
    }
  );
}

export function updateAdminAuthContext(userId) {
  // updateUserAuthContext(userId, "admin", accessToken);
  // getUserByEmail(userId);
}
export function updateAirAuthContext(userId) {
  updateUserAuthContext(userId, "air");
}
// eslint-disable-next-line no-unused-vars
function getUserByEmail(email) {
  const restUrl = "/opsadmin/rest-db/function/getUserByEmail";
  makeSecureRestApi(
    {
      url: restUrl,
      method: "post",
      data: { email }
    },
    result => {
      console.log(result);
      const { type, value } = result["get_user_by_email"];
      const user = JSON.parse(value);
      console.log("getUserByEmail", email, type, value, user);
    },
    error => {
      console.log(error);
      //   const msg = error["message"]
      //     ? error.message
      //     : "There was an error updating user auth context.";
      //   toast.error(msg, {
      //     closeOnClick: true
      //   });
    }
  );
}
function updateUserAuthContext(userId, contextType) {
  if (!userId || !accessToken) {
    return;
  }
  const restUrl = "/opsadmin/rest-db/function/upsertUserAuthContext";
  makeSecureRestApi(
    {
      url: restUrl,
      method: "post",
      data: { userId, contextType, authContext: accessToken }
    },
    result => {
      console.log(result);
    },
    error => {
      console.log(error);
    }
  );
}

function handleSessionExpiration(eventObject) {
  console.log("handleSessionExpiration", eventObject);
  window.dispatchEvent(
    new CustomEvent("sessioinExpired", {
      detail: { eventObject },
      bubbles: true,
      cancelable: true
    })
  );
}

function getResponseURL(response) {
  if (response && response.request && response.request.responseURL) {
    return response.request.responseURL;
  }
  return "";
}

// function updateLastAccessToken(accessToken) {
//   // TBD: update catalog admiin last login time
//   const decodedAccessToken = jwtDecode(accessToken);
//   console.log("updateLastAccessToken", accessToken, decodedAccessToken);
// }
export function makeSecureRestApi(params, respondHandler, errorHandler) {
  if (accessToken) {
    makeRestApi(params, respondHandler, errorHandler);
  }
}
function makeRestApi(params, respondHandler, errorHandler) {
  // console.log("calling makeRestApi", params);
  // Http Header
  const headers = params.headers
    ? params.headers
    : {
        Accept: "application/json",
        "Content-Type": "application/json"
      };
  if (accessToken !== null && hostname !== "localhost") {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  if (params.dataType === "csv") {
    // alert("Datatype is csv")
    headers["Content-Type"] = "text/csv";
    headers["Accept"] = "text/csv";
  }
  xmmAxios({
    url: params.url,
    method: params.method,
    data: params.data,
    params: params.params,
    headers,
    responseType: params.responseType
  })
    .then(response => {
      // console.log("response.status", response.status);
      if (response.status === 200 || response.status === 201) {
        respondHandler(response.data, response.headers);
      } else if (response.status === 401) {
        const msgKey = "xmm.portal.common.unauthorized_user";
        handleFatalApplicationAccess({
          messageKey: msgKey,
          defaultMessage: ""
        });
      }
      return response.status;
    })
    .catch(error => {
      // handle errors in common place
      // refresh access and refresh token
      // console.log("catch error at url", params.url);
      if (errorHandler) {
        const responseURL =
          error && error.response ? getResponseURL(error.response) : "";
        if (responseURL.indexOf(DealerCheck) !== -1) {
          // handleFatalApplicationAccess("dealer");
          // } else if (responseURL.indexOf(UserCheck) !== -1) {
          xlate("", error.response.data.message || error.response.data);
          // errorHandler(error);
        } else {
          if (error && error.message === NETWORK_ERROR) {
            error.message = xlate("xmm.portal.network.error");
          }
          errorHandler(error);
        }
      }
      return Promise.reject(error);
    });
}

export function throwGenericError(response, message) {
  let computedMessage = `request to ${response.config.url} failed`;
  if (!message) {
    computedMessage += " with no message";
  } else {
    computedMessage += ` with message: ${message}`;
  }
  throw new Error(computedMessage);
}

/**
 * This util to apply masking effect on full page
 */
export function showBodyMask() {
  const rootElement = document.getElementById("bodymask");
  const name = "xmm-loading-mask";
  const arr = rootElement.className.split(" ");
  if (arr.indexOf(name) === -1) {
    rootElement.className += " " + name;
  }
}
// Util to remove masking effect on full page
export function hideBodyMask() {
  const rootElement = document.getElementById("bodymask");
  rootElement.className = rootElement.className.replace(
    /\bxmm-loading-mask\b/g,
    ""
  );
}

export function getMakeBrandLogoUrl(make, variant) {
  const brandLogoUrl =
    make && variant
      ? `${baseImgUrl}/oe/xmm/getBrandLogo?make=${make}&variant=${variant}&locale=US_EN&key=DEFAULT_LOGO`
      : // `${baseUrl}/ops/proxyapi/internaloeproxy/xmm/getBrandLogo?make=${make}&variant=${variant}&locale=US_EN&key=DEFAULT_LOGO`
        "";
  return brandLogoUrl;
}

export function getVehicleImage(make, model, year, countryCode) {
  const imageUrl = `${baseImgUrl}/oe/getVehicleImage?make=${encodeURI(
    make
  )}&model=${encodeURI(model)}&year=${year}&countryCode=${countryCode}`;
  return imageUrl;
}

/**
 * returns loginUrl based on environment
 * @returns {string}
 */
export function getLoginUrl() {
  return baseLoginUrl;
}

export default xmmAxios;
/* eslint-enable no-console */
