import React from "react";
import PropTypes from "prop-types";
// import { doesEmpty } from "../../../utils/helper";
// import { PreviewContext } from "./../../preview-ops-context";
// import Button from "@cx/ui/Button";

// bedlengthId: 3
// bodynumdoorsId: 5
// bodytypeId: 11
// drivetypeId: 6
// engineconfigId: 18486
// transmissionId: 3084
// transmissioncontroltypeId: 5
// wheelbaseId: 171
function VehicleCard(props) {
  const AcesAttributeNames = {
    aspirationid: "Aspiration",
    bedlengthid: "Bed Length",
    bedtypeid: "Bed Type",
    bodynumdoorsid: "Body Number Doors",
    bodytypeid: "Body Type",
    brakeabsid: "Brake ABS",
    brakesystemid: "Brake System",
    cylinderheadtypeid: "Cylinder Head Type",
    drivetypeid: "Drive Type",
    enginebaseid: "Engine Base",
    enginedesignationid: "Engine Designation",
    enginemfrid: "Engine MFR",
    engineversionid: "Engine Version",
    enginevinid: "Engine VIN",
    frontbraketypeid: "Front Brake Type",
    frontspringtypeid: "Front Spring Type",
    fueldeliverysubtypeid: "Fuel Delivery Subtype",
    fueldeliverytypeid: "Fuel Delivery Type",
    fuelsystemcontroltypeid: "Fuel System Control Type",
    fuelsystemdesignid: "Fuel System Design",
    fueltypeid: "Fuel Type",
    ignitionsystemtypeid: "Ignition System Type",
    mfrbodycodeid: "MFR Body Code",
    rearbraketypeid: "Rear Brake Type",
    rearspringtypeid: "Rear Spring Type",
    restrainttypeid: "Restraint Type",
    steeringsystemid: "Steering System",
    steeringtypeid: "Steering Type",
    submodelid: "Submodel",
    transeleccontrolledid: "TranSelectControlled",
    transfercasebaseid: "Transfer Case Base",
    transfercaseid: "Transfer Case",
    transfercasemfrid: "Transfer Case MFR",
    transmissionbaseid: "Transmission Base",
    transmissioncontroltypeid: "Transmission Control Type",
    transmissionmfrcodeid: "Transmission MFR Code",
    transmissionmfrid: "Transmission MFR",
    transmissionnumspeedsid: "Transmission Num Speeds",
    transmissiontypeid: "Transmission Type",
    valvesperengineid: "Valve Per Engine",
    wheelbaseid: "Wheel Base"
  };

  function getAcesUndecodedAttributes(undecodedAttributes) {
    let undecodedAttributeList = [];
    if (undecodedAttributes) {
      undecodedAttributeList = undecodedAttributes
        .map(attr => AcesAttributeNames[attr])
        .sort((a, b) => {
          return a > b ? 1 : -1;
        });
    }
    const undecodedLabel =
      undecodedAttributeList.length !== 0
        ? "Undecoded Attributes: " + undecodedAttributeList.join(", ")
        : "";

    return undecodedLabel;
  }

  const { vehicleDesc, undecodedAttributes } = props;
  const {
    year,
    make,
    model,
    trim,
    engineType,
    engineSize,
    driveType,
    transmissionType
  } = vehicleDesc;

  const undecodedAttributesLabel = getAcesUndecodedAttributes(
    undecodedAttributes
  );

  return (
    <React.Fragment>
      <div className="ops-vehicle-card">
        <div>
          <b>
            {year} {make} {model}
          </b>
          <br />
          {trim} {engineType} {engineSize} {driveType} {transmissionType} <br />
          {undecodedAttributesLabel}
        </div>
        {/* <Button
          htmlId="ShowDetails"
          buttonStyle="secondaru"
          onClick={() => setShow(!show)}
        >
          Show more
        </Button> */}
      </div>
      {/* show ? <p>{"data"}</p> : "" */}
    </React.Fragment>
  );
}

export default VehicleCard;

VehicleCard.propTypes = {
  vehicleDesc: PropTypes.object,
  acesAttributes: PropTypes.array,
  undecodedAttributes: PropTypes.array
};
