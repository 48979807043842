/* eslint-disable no-console */
import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import PageHeader from "react-bootstrap/lib/PageHeader";
import Alert from "@cx/ui/Alert";
import Header from "./Header";
import Nav from "./Nav";
import { AppContext } from "./app-context";
import { getLocaleStrings } from "../i18n/LocaleSender";
import { LanguageProvider } from "../i18n/LanguageProvider";
import {
  getUrlParameter,
  // isEmptyString,
  isSameValue
} from "../commonUtil/utils/string";
import {
  authorize,
  // initializeUiConfig,
  setAccessToken,
  makeSecureRestApi,
  DEFAULT_LOCALE,
  redirectToDefaultPage,
  setLocale
} from "./../api/xmmAxios";
import { convertObjectValuesToUppercase } from "./../commonUtil/utils/object";
import { exportCSVFile, detectBrowser } from "./../commonUtil/utils/browser";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import ToastDefault from "./../commonUtil/components/Toast";
import { Footer } from "./Footer";
import DirtyCheckPopup from "./../commonUtil/components/modals/DirtyCheckPopup";
import SimpleModalDialog from "../commonUtil/dialog/SimpleModalDialog";
import "./_app.scss";
import { sleep } from "./reusable/helper";
import { getMetaTagContentByName } from "../commonUtil/utils/dom";
import * as modules from "./module-routes";
import * as gtag from "./utils/gtag-manager";
// import * as gtmEvents from "./utils/gtag-eventlist";
import { updateOperationStatuses } from "./reusable/operation";
import {
  translateObjects,
  globalOpsPreviewState
} from "./../constants/ModuleConstants";
// import { toast } from "@cx/ui/Toast";
import {
  setTranslations,
  xlate,
  SupportedLocales
} from "../commonUtil/i18n/locales";
import { initAppConfig } from "./../constants/AppConfig";
import { withOktaAuth } from "@okta/okta-react";
import { LicenseManager } from "ag-grid-enterprise";

// Add Ag-grid License here at App level
LicenseManager.setLicenseKey(
  "CompanyName=Cox Automotive, Inc.,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-018509,ExpiryDate=9_November_2022_[v2]_MTY2Nzk1MjAwMDAwMA==d11fa8520c9e0831eb796e7bb351f4f8"
  // "CompanyName=Cox Automotive Corporate Services, LLC,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-011240,ExpiryDate=9_November_2021_[v2]_MTYzNjQxNjAwMDAwMA==88b28c4f8dc655f9dfb5558f5cbcffd3"
  // "Cox_Automotive_Corporate_Services,_LLC_MultiApp_3Devs_1Deployment_6_November_2020__MTYwNDYyMDgwMDAwMA==1960d8f9aeeaebf42bc858968528884d"
);

class App extends React.Component {
  static propTypes = {
    webKey: PropTypes.string,
    userName: PropTypes.string,
    locale: PropTypes.string,
    authService: PropTypes.object,
    authState: PropTypes.object
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    // if (nextProps.authState !== prevState.authState) {
    if (nextProps.authState !== prevState.authState) {
      const { authState } = nextProps;
      const { accessToken } = authState;
      if (accessToken && prevState.hasRole === undefined) {
        authorize(
          accessToken,
          prevState.handleAuthizeResponse,
          prevState.handleAuthorizeError
        );
      } else {
        setAccessToken(accessToken);
      }
      const showLogin =
        !authState.isPending && authState.isAuthenticated === false;
      if (showLogin) {
        setTimeout(() => prevState.login(), 0);
      }
      return {
        authState
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    // Bind AppContext handler here
    this.discardUnsavedChanges = this.discardUnsavedChanges.bind(this);
    this.openSpeedBump = this.openSpeedBump.bind(this);
    // CustomEvent handlers
    this.login = this.login.bind(this);
    this.handleSessioinExpired = this.handleSessioinExpired.bind(this);
    this.handleAuthizeResponse = this.handleAuthizeResponse.bind(this);
    this.handleAuthorizeError = this.handleAuthorizeError.bind(this);
    this.saveCategoryGroupMappings = this.saveCategoryGroupMappings.bind(this);
    this.saveServiceCategoryGroupsMap = this.saveServiceCategoryGroupsMap.bind(
      this
    );

    const baseParams = this.fromQueryString();
    const webKey = baseParams && baseParams.webKey ? baseParams.webKey : "";

    const locale = DEFAULT_LOCALE;
    const localeStrings = getLocaleStrings(DEFAULT_LOCALE);
    setTranslations(localeStrings, locale);

    const { authState } = this.props;
    const { accessToken } = authState;
    if (accessToken) {
      authorize(
        accessToken,
        this.handleAuthizeResponse,
        this.handleAuthorizeError
      );
    }
    this.state = {
      authState,
      thisObject: this,
      handleAuthizeResponse: this.handleAuthizeResponse,
      handleAuthorizeError: this.handleAuthorizeError,
      login: this.login,
      hasRole: undefined,
      // global banner props
      currentPage: { parentName: "launchair", nodeName: "launchair" },
      /* dirty speedbump */
      showDirtyModal: false,
      speedBumpCallback: null,
      isAuth: false,
      authStatus: null,
      authMsg: "",
      locale: DEFAULT_LOCALE,
      localeStrings: getLocaleStrings(DEFAULT_LOCALE),
      languages: [],
      webKey,
      userName: "",
      user: {
        userName: baseParams.userName ? baseParams.userName : "",
        userId: ""
      },
      /* set default state for shared preview component */
      previewState: globalOpsPreviewState,
      /* save ag-grid controls to app-context */
      globalOperationsGrid: initAppConfig.globalOperationsGrid,
      packagesGrid: initAppConfig.packagesGrid,
      globalOpsData: {
        positionOptionsMap: {},
        positionOptions: [],
        externalOpOptions: [],
        externalPartOptions: [],
        externalFulidOptions: []
      },
      serviceCategoryGroupsMap: {},
      categoryGroups: [],
      categoryGroupsMap: {},
      versionRowSelected: null
    };
  }

  componentDidMount() {
    this.checkModuleAccess();

    window.addEventListener(
      "sessioinExpired",
      this.handleSessioinExpired,
      false
    );
    window.addEventListener("formHandleEvent", this.onFormHandle, false);
    window.addEventListener(
      "fatalApplicationAccess",
      this.handleFatalApplicationAccess,
      false
    );

    this.defaultMessage = "You are not authorized to access this page."; // xlate("xmm.portal.common.unauthorized_user");
  }

  componentWillUnmount() {
    window.removeEventListener(
      "sessioinExpired",
      this.handleSessioinExpired,
      false
    );
    window.removeEventListener("formHandleEvent", this.onFormHandle, false);
    window.removeEventListener(
      "fatalApplicationAccess",
      this.handleFatalApplicationAccess,
      false
    );
    // const { authService } = this.props;
    // authService.logout();
  }

  async login() {
    const { authState, authService } = this.props;
    const { isAuthenticated } = authState;
    if (!isAuthenticated) {
      authService.login("/");
    }
  }

  onFormHandle = event => {
    const { detail } = event;
    this.setState({
      activeFormHandle: detail
    });
  };

  initializeSession = () => {
    this.authenticate();
  };

  authenticate() {
    makeSecureRestApi(
      {
        url: "/opsadmin/security/authorize",
        method: "get"
      },
      response => {
        //
        console.log("authenticate", response);
      },
      error => {
        //
        console.log(error);
      }
    );
  }
  handleAuthizeResponse(response) {
    console.log(response);

    const { email, role, isxmmadmin } = response;
    this.setState({
      userId: response["user_id"],
      userName: response["user_name"],
      role,
      email,
      hasRole: true,
      xmmadmin: isxmmadmin
    });
  }
  handleAuthorizeError(error) {
    console.log(error);
    const authStatus = "noaccess";
    const errorMessage = error.message ? error.message : this.defaultMessage;
    this.setState({ authStatus, authMsg: errorMessage, hasRole: false });
  }

  openSpeedBump = (event, callback) => {
    // console.log("call - open dirty popup");
    this.setState(prevState => ({
      showDirtyModal: !prevState.showDirtyModal,
      speedBumpCallback: callback
    }));
  };

  closeSpeedBump = () => {
    // console.log("call - close dirty popup");
    this.setState(prevState => ({
      showDirtyModal: false
    }));
  };

  // This handler is part of app-context; pass callback handler from children; show speed bump when form is dirty
  discardUnsavedChanges = (event, callback, isDirtyFunc) => {
    const { activeFormHandle } = this.state;
    const isDirty = isDirtyFunc ? isDirtyFunc() : activeFormHandle.isDirty();
    if (isDirty) {
      // show dirty popup when form is dirty
      this.openSpeedBump(event, callback);
      return false;
    } else {
      // true - child calls to close slider
      return true;
    }
  };

  handleSessioinExpired = event => {
    const { isAuth } = this.state;
    console.log("handleSessioinExpired: isAuth=", isAuth);
    if (isAuth) {
      // session was authorized before
      setTimeout(() => {
        this.setState({ showSessionExpired: true });
      }, 500);
    } else {
      // session was never authorized
      redirectToDefaultPage();
    }
  };
  // mapFatalErrors = message => {
  //   let errorMessage = message;
  //   if (message === "503 Service Temporarily Unavailable") {
  //     errorMessage = xlate("xmm.portal.errors.service_not_available");
  //   }
  //   return errorMessage;
  // }
  handleFatalApplicationAccess = event => {
    let defaultLocale = window.navigator.language;
    if (!defaultLocale) {
      defaultLocale = DEFAULT_LOCALE;
    } else {
      defaultLocale = defaultLocale.replace("-", "_");
      if (!SupportedLocales.includes(defaultLocale)) {
        defaultLocale = DEFAULT_LOCALE;
      }
    }
    this.setDefaultLocale(defaultLocale, []);
    const { messageKey, defaultMessage } = event.detail;
    // const { localeStrings } = this.state;
    const authStatus = "noaccess";
    // const authMsg = type === "dealer" ? "Unauthorized Dealer" : "Unauthorized User";
    const errorMessage = messageKey ? xlate(messageKey) : defaultMessage;
    this.setState({ authStatus, authMsg: errorMessage });
  };

  /* Util - Updates App Context with ag-grid controls state */
  setGlobalOperationsGridState = globalOperationsGrid => {
    this.setState({
      globalOperationsGrid
    });
  };

  setPackagesGridState = packagesGrid => {
    this.setState({
      packagesGrid
    });
  };
  // Special case - to store shared-component preview state
  savePreviewState = previewState => {
    console.log("[App.js] savePreviewState", previewState);
    this.setState({ previewState });
  };
  // AppContext handler - fetch operations from child when restAPI returns first time
  updateOperationList = newlist => {
    console.log("appContext called to update operations");
    sleep(1000).then(() => {
      this.setState(prevState => {
        return { operationlist: newlist };
      });
    });
  };
  updateInspectionItemMap = inspectionItemMap => {
    this.setState({ inspectionItemMap });
  };
  // Handler - to update variant filters called from dashboard clicks, used to launch page with these fitlers
  updateVariantFilters = variantFilters => {
    this.setState(prevState => {
      return { variantFilters };
    });
  };
  /* AppContext handler - update add/edit case operation to parent's state (pass as context to children)
     bulkedit case - update parent' state with list of operations
  */
  updateOperationAfterSave = (action, responseData) => {
    const { operationlist } = this.state;
    if (action === "edit") {
      // find matching record
      const recIndex = operationlist.findIndex(item =>
        isSameValue(item.serviceId, responseData.serviceId)
      );
      operationlist[recIndex] = responseData;
      // console.log("updatedItem", operationlist[recIndex]);
    } else if (action === "add") {
      operationlist.push(responseData);
    } else if (action === "bulk") {
      if (responseData.length !== 0) {
        responseData.forEach(service => {
          const recIndex = operationlist.findIndex(item =>
            isSameValue(item.serviceId, service.serviceId)
          );
          console.log(
            "App-context updated with bulkedit results",
            operationlist[recIndex]
          );
          operationlist[recIndex] = service;
        });
      }
    } else if (action === "delete") {
      const deletedOperations = responseData;
      for (let index = deletedOperations.length - 1; index >= 0; index--) {
        const service = deletedOperations[index];
        const recIndex = operationlist.findIndex(item =>
          isSameValue(item.serviceId, service.serviceId)
        );
        if (recIndex >= 0) {
          operationlist.splice(recIndex, 1);
        }
      }
    } else if (action === "updateStatuses") {
      const operationStatuses = responseData;
      for (let index = operationStatuses.length - 1; index >= 0; index--) {
        const opStatuses = operationStatuses[index];
        const recIndex = operationlist.findIndex(item =>
          isSameValue(item.serviceId, opStatuses.serviceId)
        );
        if (recIndex >= 0) {
          updateOperationStatuses(operationlist[recIndex], opStatuses);
        }
      }
    }
    // update App level state
    this.setState(() => {
      return {
        operationlist
      };
    });
  };

  replaceCatalog = (catalogList, catalog) => {
    for (let index = 0; index < catalogList.length; index++) {
      if (catalog.make === catalogList[index].make) {
        Object.assign(catalogList[index], catalog);
        // console.log(catalogList[index]);
        break;
      }
    }
    return catalogList;
  };
  // AppContext handler - to update record of dealerCatalogs; when setting page level fields are saved
  updateDealerCatalogs = catalog => {
    const { dealerCatalogs, makelist } = this.state;
    this.replaceCatalog(dealerCatalogs, catalog);
    this.replaceCatalog(makelist, catalog);
    const mixedPricing = this.setMixedPricing(dealerCatalogs);
    this.setState(prevState => {
      return {
        mixedPricing,
        dealerCatalogs,
        makelist
      };
    });
  };

  // AppContext handler - to update makelist loaded once for dealercode
  updateMakeList = newlist => {
    // console.log("appContext called to update makes");
    this.setState(prevState => {
      return { makelist: newlist };
    });
  };
  // AppContext handler - to update service categories loaded once for locale, dealercode
  updateServiceCategories = newlist => {
    // console.log("appContext called to update categories");
    const serviceCategoryValueLabelMap = {};
    newlist.forEach(serviceCategory => {
      const { label, value } = serviceCategory;
      serviceCategoryValueLabelMap[value] = label;
    });
    this.setState(prevState => {
      return { serviceCategories: newlist, serviceCategoryValueLabelMap };
    });
  };

  setContentPriceStatuses = contentPriceStatuses => {
    this.setState({ contentPriceStatuses });
  };

  setManualOpcodes = manualOpcodes => {
    this.setState({ manualOpcodes });
  };
  setCancelOpcodeValidationDate = cancelOpcodeValidationDate => {
    this.setState({ cancelOpcodeValidationDate });
  };
  // This way any UserContext.Consumer could access the setter to change the user.
  setUser = user => {
    this.setState({ user, isAuth: true });
  };
  setDealer = dealer => {
    this.processLocales(dealer);
    this.setState({ dealer, dealerCode: dealer.dealerCode });
  };
  processLocales(dealer) {
    let localeList =
      dealer.locale && dealer.locale.length !== 0 ? dealer.locale[0] : [];
    if (!Array.isArray(localeList)) {
      localeList = [localeList];
    }
    const { defaultLocale } = dealer;
    this.setDefaultLocale(defaultLocale, localeList);
  }
  convertToLanguageCountry(locale) {
    let langPrefix = locale.substr(0, 2);
    const countryPrefix = locale.substr(3, 2);
    if (langPrefix === "en") {
      langPrefix = "English";
    } else if (langPrefix === "fr") {
      langPrefix = "Français";
    } else if (langPrefix === "es") {
      langPrefix = "Español";
    }
    return `${langPrefix} (${countryPrefix})`;
  }
  setDefaultLocale(defaultLocale, localeList) {
    // get a list of dealer supported locales
    const locales = localeList.map(locale => {
      return locale.localeCode;
    });
    // e.g locales = [ "en_CA", "fr_CA" ]
    let languages = [];
    let hasNonbaseLocale = false;
    if (locales && locales.length !== 0) {
      languages = locales.map(lc => {
        const label = this.convertToLanguageCountry(lc);
        const value = lc;
        if (lc.indexOf("fr_") !== -1) {
          hasNonbaseLocale = true;
        }
        return { label, value };
      });
    }

    // get dealer default locale, it is not specified and there is no supported locale, then default to en_US
    const baseLocale = !defaultLocale
      ? locales.length !== 0
        ? locales[0]
        : DEFAULT_LOCALE
      : defaultLocale;

    // default to base locale before processing the user locale from browser
    let currentLocale = baseLocale;

    // get locale query parameter if it is passed
    const baseParams = this.fromQueryString();
    const localeQueryParam = baseParams.locale ? baseParams.locale : null;

    // default to browser locale if query parameter is not passed
    let userLocale = localeQueryParam
      ? localeQueryParam
      : window.navigator.language;

    // check to make sure userLocale supported by dealer
    if (userLocale) {
      // convert user locale separator from hyphen to underscore
      userLocale = userLocale.replace("-", "_");
    }
    // update currentLocale to userLocale if it is supported by dealer
    if (userLocale) {
      if (locales.length === 0) {
        // if supported locales are empty then default it to baseLocale
        currentLocale = baseLocale;
      } else {
        if (locales.includes(userLocale)) {
          // userLocale matches one of the supported locales
          currentLocale = userLocale;
        } else if (locales.includes(baseLocale)) {
          // baseLocale matches one of the supported locales
          currentLocale = baseLocale;
        } else {
          // neither baseLocale or userLocale matches any of the supported locale
          currentLocale = locales[0];
        }
      }
    }

    // save locale to state; make sure baseLocale and locale can't be different countries: e.g. en_CA vs en_US
    const locale = currentLocale;
    // const locale =
    //   baseLocale !== currentLocale && currentLocale.indexOf("en_") !== -1
    //     ? baseLocale
    //     : currentLocale;
    // set localeStrings
    let localeStrings = getLocaleStrings(locale);
    // add "locale" to localeStorage
    setLocale(locale);

    // TODO: Removed the if block once translation is done
    if (locale !== DEFAULT_LOCALE) {
      const defaultLocaleStrings = getLocaleStrings(DEFAULT_LOCALE);
      // this.exportLocaleStringsToCsvFormat(defaultLocaleStrings);
      // if not default locale then copy uppercase values before apply the translation
      const baseLocaleStrings = convertObjectValuesToUppercase(
        defaultLocaleStrings
      );
      localeStrings = Object.assign(baseLocaleStrings, localeStrings);
    }
    // set translation strings into translations list in locales.js
    setTranslations(localeStrings, locale);
    this.setState({
      baseLocale,
      languages,
      hasNonbaseLocale,
      locale,
      localeStrings
    });
    // Note: update constant objects with localeStrings values
    translateObjects(localeStrings);
  }
  exportLocaleStringsToCsvFormat(localeStrings) {
    const csvStrings = Object.keys(localeStrings).map(key => {
      return `${key}, ${localeStrings[key]}`;
    });
    exportCSVFile(null, csvStrings, "Locale Strings in English");
    console.log("csvStrings", csvStrings);
  }
  /* This method returns query params from window.location */
  fromQueryString = () => {
    const baseParams = {};
    baseParams.locale = getUrlParameter("locale");
    baseParams.webKey = getUrlParameter("webKey");
    baseParams.userName = getUrlParameter("userName");
    baseParams.dealerCode = "";
    return baseParams;
  };

  /* This call check if user is authorized to access page */
  checkModuleAccess() {
    this.initializeSession();
  }
  /* optional check if we want to display unsupported browser msg */
  checkBrowser() {
    const browserName = detectBrowser();
    let access = true;
    if (browserName === "Microsoft") {
      access = false;
    }
    return access;
  }
  // loadUiConfig() {
  //   const { origin } = window.location;
  //   makeSecureRestApi(
  //     {
  //       url: `${origin}/data/UIConfig.json`,
  //       method: "get"
  //     },
  //     response => {
  //       console.log(response);
  //       // initializeUiConfig(response);
  //       this.checkModuleAccess();
  //     },
  //     error => {
  //       toast.error("Error loading UiConfig", {
  //         closeOnClick: true
  //       });
  //     }
  //   );
  // }
  /*
     Initialize GTM dataLayer first time with unique account/application details at index.html
     Push event data, userTimings, pageviews to GTM dataLayer
    */
  initGTagDataLayer() {
    const { user, locale } = this.state;
    const env = gtag.getEnvironment();
    let trainingRole = undefined;
    let branch = getMetaTagContentByName("xtime:gitBranch");
    let build = getMetaTagContentByName("xtime:buildNumber");
    branch = branch.indexOf("%REACT_APP") !== -1 ? undefined : branch;
    build = build.indexOf("%REACT_APP") !== -1 ? undefined : "-" + build;
    const appVersion = branch + build;

    // Only for "automationuser" user, push trainingRole as "AUTOMATION" ( to differ from other real-time users)
    if (Object.keys(user).length > 0) {
      if (user.username && user.username === "automationuser") {
        trainingRole = "AUTOMATION";
      }
      /* Push GA dimension "trainingRole" to differnciate dealers from QA automation / xtime support team will be 'XTEMPLOYEE' */
      if (user.trainingRole) {
        trainingRole = user.trainingRole;
      }
    }

    // Cox related common details
    const eventObj = {
      common: {
        dataLayerVersion: 1,
        user: {
          bridgeUser: undefined,
          applicationUser: user.username,
          userType: trainingRole,
          isInternalUser: true
        },
        application: {
          businessUnitName: "Xtime",
          name: "CatalogAdmin",
          version: !appVersion ? undefined : appVersion,
          environment: env,
          isProduction: env === "production" ? true : false
        },
        context: {
          dealershipId: undefined,
          dealershipName: undefined
        }
      },
      // Push application values like userId, role, enviornment, webKey to dataLayer for GA
      appName: "CatalogAdmin",
      appVersion: !appVersion ? undefined : appVersion,
      environment: env,
      user: user.userId,
      locale
    };
    this.pushGTagAppDetails(eventObj);
  }
  /* For GTM session data, we want to initialize the data layer, fire off a page view, and then collect User events */
  pushGTagAppDetails(eventObj) {
    gtag.pushDimensions(eventObj);
    window.dataLayer.push({
      event: "dataLayerReady",
      dataLayerReady: true
    });
  }
  saveServiceCategoryGroupsMap(serviceCategoryGroupsMap) {
    this.setState({ serviceCategoryGroupsMap });
  }
  saveCategoryGroupMappings(categoryGroups, categoryGroupsMap) {
    this.setState({ categoryGroups, categoryGroupsMap });
  }
  updateVersionRow = data => {
    this.setState({ versionRowSelected: data });
  };

  render() {
    const { authState, authStatus } = this.state;
    const {
      hasRole,
      role,
      userId,
      userName,
      locale,
      localeStrings,
      globalOpsData,
      previewState,
      xmmadmin,
      serviceCategoryGroupsMap,
      categoryGroups,
      categoryGroupsMap,
      versionRowSelected
    } = this.state;
    console.log("authState", authState);
    const showApp = !authState.isPending && authState.isAuthenticated;
    const showLogin =
      !authState.isPending && authState.isAuthenticated === false;
    /* set localstate to context here */
    const contextValue = {
      qsParams: window.location.search,
      userId,
      userName,
      role,
      locale,
      localeStrings,
      globalOpsData,
      previewState,
      /* Ag-Grid control saved in context */
      globalOperationsGrid: this.state.globalOperationsGrid,
      packagesGrid: this.state.packagesGrid,
      // currentPage and discardUnsavedChanges are used to handle speed bump on current page in NavMenuIteem.onClick event
      currentPage: this.state.currentPage,
      serviceCategoryGroupsMap,
      categoryGroups,
      categoryGroupsMap,
      discardUnsavedChanges: this.discardUnsavedChanges,
      setGlobalOperationsGridState: this.setGlobalOperationsGridState,
      setPackagesGridState: this.setPackagesGridState,
      savePreviewState: this.savePreviewState,
      saveServiceCategoryGroupsMap: this.saveServiceCategoryGroupsMap,
      saveCategoryGroupMappings: this.saveCategoryGroupMappings,
      updateVersionRow: this.updateVersionRow,
      versionRowSelected
    };
    let content = (
      <div style={{ margin: 25 }}>
        <LoadingIndicator htmlId="LoadingMask" size="large" />
      </div>
    );
    const dirtyPopup = (
      <DirtyCheckPopup
        showDirtyModal={this.state.showDirtyModal}
        title={localeStrings["xmm.portal.speed_bump.title"]}
        message={localeStrings["xmm.portal.speed_bump.message"]}
        okText={localeStrings["xmm.portal.speed_bump.discard"]}
        cancelText={localeStrings["xmm.portal.speed_bump.stay_on_page"]}
        cancelAction={this.closeSpeedBump}
        discardAction={this.state.speedBumpCallback}
      />
    );
    const sessionExpiredPopup = (
      <SimpleModalDialog
        show={this.state.showSessionExpired}
        title={"Session Expired"}
        message={
          "Your session has been expired.  Please close it to see the login page."
        }
        actionButtonLabel={"OK"}
        dialogHtmlId={"sessionExpiredId"}
        actionHtmlId={"actionId"}
        titleId={"xmm.portal.session.expired.title"}
        messageId={"xmm.portal.session.expired.message"}
        actionButtonLabelId={"xmm.portal.common.ok_button"}
        doAction={() => {
          redirectToDefaultPage();
          this.setState({ showSessionExpired: false });
        }}
        doClose={() => {
          redirectToDefaultPage();
          this.setState({ showSessionExpired: false });
        }}
      />
    );
    const bannerSection = null;
    /* Global banner - add conditions to show/hide banner */
    /*
    const isStaging = contextValue.dealerCode.indexOf("__STG_") !== -1;
    if (isStaging && this.state.genericMsg) {
      bannerSection = (
        <Alert
          htmlId="appBanner"
          className=""
          type={this.state.statusType}
          displayCloseButton={false}
        >
          {this.state.genericMsg}
        </Alert>
      );
    }
    */
    // Render children pages only when dealerCode is ready
    if (hasRole === false || authStatus === "noaccess") {
      const { authMsg } = this.state;
      content = (
        <Card htmlId="CardErrorPage">
          <div style={{ width: "100%" }}>
            <PageHeader>
              {window.location.pathname === "/xmmAdmin"
                ? xlate("xmmadmin.portal.common.xmm_name")
                : xlate("xmmadmin.portal.common.app_name")}
            </PageHeader>
            <Alert htmlId="systemAlert" type="danger">
              {authMsg}
            </Alert>
          </div>
        </Card>
      );
    } else if (showApp && hasRole) {
      content = (
        <div className="xtime-support">
          <LanguageProvider
            locale={locale.replace("_", "-")} // "en-US"
            key={locale.replace("_", "-")}
            messages={localeStrings}
          >
            <AppContext.Provider value={contextValue}>
              <div className="main-layout">
                <div id="wrapper" className="content">
                  <Header userName={userName} />
                  <Nav
                    links={modules.getModuleMapping(role, xmmadmin)}
                    role={role}
                  />
                  <ToastDefault />

                  <div className="page-wrapper">
                    {bannerSection}
                    {dirtyPopup}
                    {sessionExpiredPopup}
                    <Switch>
                      {modules.routes.map(route => (
                        <Route
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          component={route.main}
                        />
                      ))}
                    </Switch>
                    <Footer />
                  </div>
                </div>
              </div>
            </AppContext.Provider>
          </LanguageProvider>
        </div>
      );
    } else if (showLogin) {
      content = (
        <div>
          {/* <Button size="lg" onClick={this.login}>
            Go to OKTA Login Page
      </Button> */}
          <h1>Loading...</h1>
        </div>
      );
    }
    return content;
  }
}

export default withOktaAuth(withRouter(App));
