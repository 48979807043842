/* eslint-disable no-console */
import React, { Component } from "react";
import IconPlay from "@cx/ui/Icons/IconPlay";
import { AppContext } from "../../../components/app-context";
import { xlate } from "../../../commonUtil/i18n/locales";
import motorIcon from "../../../images/MB_128.png";
import metaVehIcon from "../../../images/MV_128.png";
import serviceStudioIcon from "../../../images/SS_128.png";
import { hasUserRole } from "../../../role/roleUtil";
import {
  namespace,
  servicestudioUrl,
  updateAirAuthContext
} from "../../../api/xmmAxios";

export default class AirLauncher extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onClickMotorBrowser = this.onClickMotorBrowser.bind(this);
    this.onClickMetaVehicle = this.onClickMetaVehicle.bind(this);
    this.onClickServiceStudio = this.onClickServiceStudio.bind(this);
    this.onClickFirstTimeDownload = this.onClickFirstTimeDownload.bind(this);
    this.onClickDownloadServiceStudio = this.onClickDownloadServiceStudio.bind(
      this
    );
    this.onFixAirAppUpdate = this.onFixAirAppUpdate.bind(this);
    const platform = window.navigator.platform;

    const isMacOS = platform && platform.indexOf("Mac") >= 0;
    this.initializeLocaleValues(isMacOS);
    const { role } = context;
    const isMotorEnabled =
      hasUserRole("launchAir/motor", role) && namespace.indexOf("aus") === -1;
    const isMVEditorEnabled = hasUserRole("launchAir/mveditor", role);
    const isSSEnabled = hasUserRole("launchAir/servicestudio", role);
    this.state = {
      isMacOS,
      isMotorEnabled,
      isMVEditorEnabled,
      isSSEnabled,
      role
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  initializeLocaleValues(isMacOS) {
    this.airAppsLabel = xlate("xmmadmin.portal.common.air_apps");
    this.serviceStudioLabel = xlate("xmmadmin.portal.common.service_studio");
    this.metaVehicleEditorLabel = xlate(
      "xmmadmin.portal.common.meta_vehicle_editor"
    );
    this.motorBrowserLabel = xlate("xmmadmin.portal.common.motor_browser");
    this.airAppDownloadLabel = xlate(
      "xmmadmin.portal.common.first_time_user_download"
    );
    this.firstTimeInstallationStepsLabel = xlate(
      "xmmadmin.portal.common.first_time_installation_steps"
    );
    this.firstTimeInstallationStep1 = xlate(
      "xmmadmin.portal.common.first_time_installation_step_1"
    );
    this.firstTimeInstallationStep1a = xlate(
      "xmmadmin.portal.common.first_time_installation_step_1a"
    );
    this.firstTimeInstallationStep2 = xlate(
      "xmmadmin.portal.common.first_time_installation_step_2"
    );
    this.firstTimeInstallationStep3 = xlate(
      "xmmadmin.portal.common.first_time_installation_step_3"
    );
    this.firstTimeInstallationStep4 = xlate(
      "xmmadmin.portal.common.first_time_installation_step_4"
    );
    this.firstTimeInstallationStep5 = xlate(
      "xmmadmin.portal.common.first_time_installation_step_5"
    );
    this.osInstallationLabel = xlate(
      isMacOS
        ? "xmmadmin.portal.common.mac_os_installation"
        : "xmmadmin.portal.common.windows_os_installation"
    );
    this.fixAirAppUpdateLabel = xlate(
      "xmmadmin.portal.common.mac_os_fix_airapp_update"
    );
    this.fixAirAppUpdateUrlLabel = xlate(
      "xmmadmin.portal.common.mac_os_fix_airapp_update_url"
    );
  }
  getEnvUrl(namespace) {
    let domain = `nonprod.xtime.com/${namespace}`;
    if (namespace === "prod") {
      domain = `xtime.com/${namespace}`;
    } else if (namespace === "prodaus") {
      domain = `xtime.net.au/${namespace}`;
    } else if (namespace === "stg1aus" || namespace === "stg4aus") {
      domain = `xtime.net.au/${namespace}`;
    }
    return `https://servicestudio.${domain}`;
  }
  onClickMetaVehicle(event) {
    window.open(`${servicestudioUrl}/oe/rest/dds/v2/launch/metavehicle`);
  }
  onClickMotorBrowser(event) {
    window.open(`${servicestudioUrl}/oe/rest/dds/v2/launch/motor`);
  }
  onClickServiceStudio(event) {
    window.open(`${servicestudioUrl}/oe/rest/dds/v2/launch/servicestudio`); // TBD
    const { userId } = this.context;
    updateAirAuthContext(userId);
  }
  onClickFirstTimeDownload(event) {
    const { showFirstTimeDownload } = this.state;
    this.setState({ showFirstTimeDownload: !showFirstTimeDownload });
  }
  onFixAirAppUpdate(event) {
    const { showFixAirAppUpdate } = this.state;
    this.setState({ showFixAirAppUpdate: !showFixAirAppUpdate });
  }
  onClickDownloadServiceStudio(event) {
    //
  }
  launchMotorBrowser() {
    window.open(`${servicestudioUrl}/oe/rest/dds/v2/launch/motor`);
  }
  launchMetaVehicle() {
    window.open(`${servicestudioUrl}/oe/rest/dds/v2/launch/metavehicle`);
  }
  getAIRRuntimeDownloadURL(isMacOS) {
    return isMacOS
      ? "https://servicestudio-air-nonprod-west2.s3-us-west-2.amazonaws.com/flex/adobe_runtime/mac/AdobeAIR.dmg"
      : "https://servicestudio-air-nonprod-west2.s3-us-west-2.amazonaws.com/flex/adobe_runtime/win/AdobeAIRInstaller.exe";
  }
  getFixAdobeAirFrameworkUrl(namespace) {
    const bucketSuffix = this.getS3BucketSuffix(namespace);
    return `https://servicestudio-air-${bucketSuffix}.amazonaws.com/flex/servicestudio/${namespace}/executable/fixadobeairframework.tar.gz`;
  }
  getEnvSuffixString(namespace) {
    return namespace === "prod" ? "" : `-${namespace}`;
  }
  getS3BucketSuffix(namespace) {
    if (namespace === "prod") {
      return "prod-west2.s3-us-west-2";
    } else if (namespace === "stg4") {
      return "stg-west2.s3-us-west-2";
    } else if (namespace === "prodaus") {
      return "prod-apse2.s3-ap-southeast-2";
    } else if (namespace === "stg1aus") {
      return "stg-apse2.s3-ap-southeast-2";
    }
    return "nonprod-west2.s3-us-west-2";
  }
  getExtensionString(isMacOS) {
    return isMacOS ? "tar.gz" : "bat";
  }
  getFixAirAppUpdateUrl() {
    const env = namespace === "prod" ? "" : `-${namespace}`;
    const bucketSuffix = this.getS3BucketSuffix(namespace);
    return `https://servicestudio-air-${bucketSuffix}.amazonaws.com/flex/servicestudio/${namespace}/executable/fixautoupdate${env}.tar.gz`;
  }
  getServiceStudioAirDownloadUrl() {
    // return "#";
    const { isMacOS } = this.state;
    const env = this.getEnvSuffixString(namespace);
    const extension = this.getExtensionString(isMacOS);
    const bucketSuffix = this.getS3BucketSuffix(namespace);
    return `https://servicestudio-air-${bucketSuffix}.amazonaws.com/flex/servicestudio/${namespace}/executable/servicestudio${env}.${extension}`;
  }
  getMotorAirDownloadUrl() {
    const { isMacOS } = this.state;
    const env = this.getEnvSuffixString(namespace);
    const bucketSuffix = this.getS3BucketSuffix(namespace);

    // Mac and Window insaller names are somehow different: motorapp vs motor.  We can fix it in the future
    return isMacOS
      ? `https://servicestudio-air-${bucketSuffix}.amazonaws.com/flex/motor/${namespace}/executable/motorapp${env}.tar.gz`
      : `https://servicestudio-air-${bucketSuffix}.amazonaws.com/flex/motor/${namespace}/executable/motor${env}.bat`;
  }
  getMetaVehicleAirDownloadUrl() {
    const { isMacOS } = this.state;
    const env = this.getEnvSuffixString(namespace);
    const extension = this.getExtensionString(isMacOS);
    const bucketSuffix = this.getS3BucketSuffix(namespace);
    return `https://servicestudio-air-${bucketSuffix}.amazonaws.com/flex/metaveheditor/${namespace}/executable/metaveheditor${env}.${extension}`;
  }
  render() {
    const {
      isMacOS,
      isMotorEnabled,
      isMVEditorEnabled,
      isSSEnabled,
      // role,
      showFirstTimeDownload,
      showFixAirAppUpdate
    } = this.state;
    const motorDisabled = !isMotorEnabled; // ? "" : "disabled";
    const mvEditorDisabled = !isMVEditorEnabled; // ? "" : "disabled";
    const ssDisabled = !isSSEnabled; // ? "" : "disabled";
    const motorClass = motorDisabled ? "hide" : "air-app-icon-box";
    const mvEditorClass = mvEditorDisabled ? "hide" : "air-app-icon-box";
    const ssClass = ssDisabled ? "hide" : "air-app-icon-box";

    const motorLinkClass = motorDisabled ? "hide" : "link";
    const mvEditorLinkClass = mvEditorDisabled ? "hide" : "link";
    const ssLinkClass = ssDisabled ? "hide" : "link";

    const env = namespace ? `- ${namespace}` : "";
    const envLabel = namespace !== "" ? `(${namespace.toUpperCase()})` : "";
    const airRuntimeDownloadUrl = this.getAIRRuntimeDownloadURL(isMacOS);
    const fixAdobeAirFrameworkUrl = this.getFixAdobeAirFrameworkUrl(namespace);
    const fixAirAppUpdateUrl = this.getFixAirAppUpdateUrl();
    const firstTimeDownContentClass = showFirstTimeDownload ? "" : "hide";
    const fixMacAdobeAirFrameworkClass = isMacOS ? "" : "hide";
    const fixMacAirUpdateIconClass = isMacOS
      ? "air-app-download-toggle"
      : "hide";
    const fixMacAirAppUpdateClass =
      isMacOS && showFixAirAppUpdate ? "" : "hide";
    const downloadMotorUrl = this.getMotorAirDownloadUrl();
    const downloadMetaVehicleUrl = this.getMetaVehicleAirDownloadUrl();
    const downloadServiceStudioUrl = this.getServiceStudioAirDownloadUrl();
    const downloadIconClass = showFirstTimeDownload
      ? "air-app-download-svgicon"
      : "";
    const updateIconClass = showFixAirAppUpdate
      ? "air-app-download-svgicon"
      : "";
    return (
      <div className="xmm-center-container">
        <h3>
          {this.airAppsLabel} {envLabel}
        </h3>
        <div className="air-apps-">
          <div className="air-apps-header">
            <div
              className={ssClass}
              disabled={ssDisabled}
              onClick={this.onClickServiceStudio}
            >
              <img
                className="air-app-icon"
                src={serviceStudioIcon}
                alt={serviceStudioIcon}
              />
              <div className="air-app-label">{this.serviceStudioLabel}</div>
            </div>
            <div
              className={mvEditorClass}
              disabled={mvEditorDisabled}
              onClick={this.onClickMetaVehicle}
            >
              <img
                className="air-app-icon"
                src={metaVehIcon}
                alt={metaVehIcon}
              />
              <div className="air-app-label">{this.metaVehicleEditorLabel}</div>
            </div>
            <div
              className={motorClass}
              disabled={motorDisabled}
              onClick={this.onClickMotorBrowser}
            >
              <img className="air-app-icon" src={motorIcon} alt={motorIcon} />
              <div className="air-app-label">{this.motorBrowserLabel}</div>
            </div>
          </div>
          <div
            className="air-app-download-toggle"
            onClick={this.onClickFirstTimeDownload}
          >
            <IconPlay className={downloadIconClass} />
            {this.airAppDownloadLabel}
          </div>
          <div className={firstTimeDownContentClass}>
            <div className="air-app-download-box">
              <h2>{this.firstTimeInstallationStepsLabel}</h2>
              <div>
                1){" "}
                <a href={airRuntimeDownloadUrl}>
                  {this.firstTimeInstallationStep1}
                </a>
              </div>
              <div className={fixMacAdobeAirFrameworkClass}>
                a.{" "}
                <a href={fixAdobeAirFrameworkUrl}>
                  {this.firstTimeInstallationStep1a}
                </a>
              </div>
              <div className="air-app-install-label">
                {this.osInstallationLabel}
              </div>
              <div>{this.firstTimeInstallationStep2}</div>
              <div className={ssLinkClass} disabled={!isSSEnabled}>
                <a
                  href={downloadServiceStudioUrl}
                  onClick={this.onClickDownloadServiceStudio}
                >
                  - {this.serviceStudioLabel} {env}
                </a>
              </div>
              <div className={mvEditorLinkClass} disabled={!isMVEditorEnabled}>
                <a href={downloadMetaVehicleUrl}>
                  - {this.metaVehicleEditorLabel} {env}
                </a>
              </div>
              <div className={motorLinkClass} disabled={!isMotorEnabled}>
                <a href={downloadMotorUrl}>
                  - {this.motorBrowserLabel} {env}
                </a>
              </div>
              <div className="link-end" />
              <div>{this.firstTimeInstallationStep3}</div>
              <div>{this.firstTimeInstallationStep4}</div>
              <div>{this.firstTimeInstallationStep5}</div>
            </div>
          </div>
          <div>&nbsp;</div>
          <div
            className={fixMacAirUpdateIconClass}
            onClick={this.onFixAirAppUpdate}
          >
            <IconPlay className={updateIconClass} />
            {this.fixAirAppUpdateLabel}
          </div>
          <div className={fixMacAirAppUpdateClass}>
            <div className="air-app-download-box">
              <div>
                <a href={fixAirAppUpdateUrl}>{this.fixAirAppUpdateUrlLabel}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
