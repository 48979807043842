/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import Col from "@cx/ui/Col";
import PriceInput from "@cx/ui/PriceInput";
import TextInput from "@cx/ui/TextInput";
import { round, isFloatingNumber } from "../../../utils/value";

class LaborPartsPane extends React.Component {
  // static contextType = PreviewContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.laborPartsTotal !== prevState.laborPartsTotal) {
      const { laborPartsTotal } = nextProps;
      return {
        laborPartsTotal
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    const { laborPartsTotal } = props;
    this.state = {
      laborPartsTotal
    };
  }

  calculateTotals(laborTotal, partsTotal) {
    let totals = 0;
    if (!isNaN(laborTotal)) {
      totals += laborTotal;
    }
    if (!isNaN(partsTotal)) {
      totals += partsTotal;
    }
    return round(totals);
  }
  calculateLaborTotal(laborRate, laborTime) {
    if (isNaN(laborRate) || isNaN(laborTime)) {
      return 0;
    }
    return round(laborRate * laborTime);
  }
  onLaborRateChange = (cxEvent, isValud, domEvent) => {
    const { value } = cxEvent.target;
    const { laborRate } = this.state.laborPartsTotal;
    if (laborRate !== value) {
      const { laborPartsTotal } = this.state;
      laborPartsTotal.laborRate = Number(value);
      this.setState({ laborPartsTotal });
    }
  };
  // round(num) {
  //   return Math.round(num * 100) / 100;
  // }

  render() {
    const {
      isPublishedCatalog,
      laborRate,
      laborTime,
      partsTotal
    } = this.state.laborPartsTotal;
    let { laborTotal, total } = this.state.laborPartsTotal;
    if (!isPublishedCatalog) {
      laborTotal = this.calculateLaborTotal(laborRate, laborTime);
      total = this.calculateTotals(laborTotal, partsTotal);
      // } else {
      //   laborRate = "";
    }
    const laborRateLabel = isFloatingNumber(laborRate)
      ? laborRate.toFixed(2)
      : laborRate;
    return (
      <React.Fragment>
        <div className="ops-labor-card">
          <Col xs={3} md={3}>
            <PriceInput
              displayLabel
              htmlId="LaborRateInput"
              label="Labor Rate"
              name="laborRate"
              value={laborRateLabel}
              onChange={this.onLaborRateChange}
              disabled={isPublishedCatalog}
            />
          </Col>
          <Col xs={3} md={3}>
            <TextInput
              displayLabel
              htmlId="HoursInput"
              label="Hours"
              name="hours"
              inputSuffix="Hours"
              value={laborTime.toFixed(1)}
              disabled={true}
            />
          </Col>
          <Col xs={3} md={3}>
            <PriceInput
              displayLabel
              htmlId="LaborInput"
              label="Labor"
              name="labor"
              value={laborTotal.toFixed(2)}
              disabled={true}
            />
          </Col>
          <Col xs={3} md={3}>
            <PriceInput
              displayLabel
              htmlId="PartsInput"
              label="Parts"
              name="parts"
              value={partsTotal.toFixed(2)}
              disabled={true}
            />
          </Col>
          <Col xs={3} md={3}>
            <PriceInput
              displayLabel
              htmlId="TotalInput"
              label="Total"
              name="total"
              value={total.toFixed(2)}
              disabled={true}
            />
          </Col>
        </div>
      </React.Fragment>
    );
  }
}

export default LaborPartsPane;

LaborPartsPane.propTypes = {
  laborPartsTotal: PropTypes.object
};
