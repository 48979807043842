/* eslint-disable no-console */
import React, { Component } from "react";
import Button from "@cx/ui/Button";
import PreviewModal from "../pages/Preview";
import { AppContext } from "../app-context";
import { PropTypes } from "prop-types";

class PreviewButton extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.showPreviewModal = this.showPreviewModal.bind(this);
    this.closePreviewModal = this.closePreviewModal.bind(this);
    this.previewActionHandler = this.previewActionHandler.bind(this);

    this.state = {
      showModal: false,
      disableAction: false
    };
  }

  /**
   * Add event listener
   */
  componentDidMount() {}

  showPreviewModal = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showModal: !prevState.showModal,
      disableAction: true
    }));
  };

  closePreviewModal = event => {
    this.setState({
      showModal: false,
      disableAction: false
    });
  };

  previewActionHandler = (event, mode) => {
    console.log("preview action triggered");
  };

  render() {
    const { localeStrings } = this.context;
    const { className, disabled } = this.props;
    let showModal = null;
    if (this.state.showModal) {
      showModal = (
        <PreviewModal
          show={this.state.showModal}
          okAction={this.previewActionHandler}
          closeModal={this.closePreviewModal}
        />
      );
    }
    return (
      <span className="float-right">
        <Button
          htmlId="PreviewBtn"
          size="small"
          buttonStyle="default"
          className={className}
          disabled={disabled}
          onClick={this.showPreviewModal}
        >
          {localeStrings["xmmadmin.portal.nav.preview"]}
        </Button>
        {showModal}
      </span>
    );
  }
}

export default PreviewButton;

PreviewButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool
};

/* eslint-enable no-console */
