/* eslint-disable no-console */
import React, { Component } from "react";
import { AppContext } from "../../../components/app-context";
import Button from "@cx/ui/Button";
import SelectInput from "@cx/ui/SelectInput";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import { toast } from "@cx/ui/Toast";
import TextInput from "@cx/ui/TextInput";
import CustomLoadingOverlay from "./../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import CheckboxCell from "../../../components/reusable/CheckboxCell";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../api/xmmAxios";
import { VersionStatusMap } from "../../../constants/ModuleConstants";
import { AgGridReact } from "ag-grid-react";
import { isSameValue } from "../../../commonUtil/utils/string";
import { formatDateTimezone } from "../../../commonUtil/utils/date";
import { boolToStringFormatter } from "../../../commonUtil/utils/formatter";
import { applyCustomKeyNavigation } from "../../../commonUtil/utils/keyNavigation";

class MainPage extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onGridReady = this.onGridReady.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.setAutoHeight = this.setAutoHeight.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);
    this.onChangeMotor = this.onChangeMotor.bind(this);
    this.saveNewVersion = this.saveNewVersion.bind(this);
    // this.publishDateFormatter = this.publishDateFormatter.bind(this);

    const localeStrings = context.localeStrings;
    const gridOptions = {
      // other state props
      pageTitle: "Version",
      motorSchema: [],
      motorSelected: "",
      // ag-grid props
      rowData: null, // should be null - fix to skip "No records found" msg on grid load.
      selectableFluids: [],
      newFluids: [],
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        // cellClass: "xmm-wrap-cell",
        // autoHeight: true,
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        suppressMenu: false,
        sortingOrder: ["asc", "desc", null],
        filter: false,
        filterParams: {
          buttons: ["clear"]
        },
        floatingFilter: false, // true - enable column header filters
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      // multiSortKey: "ctrl",
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },

      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
        selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
        totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
        totalAndFilteredRows:
          localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
        noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      // domLayout: "autoHeight",
      // setAutoHeight: this.setAutoHeight
      inputTestDealer: "",
      lastInputTestDealer: "",
      rowSelection: "single",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          }
        ],
        hiddenByDefault: false
      }
    };
    this.state = gridOptions;
  }
  componentDidMount() {
    this.loadMotorSchema();
    window.addEventListener(
      "saveCellEditEvent",
      this.handleLiveVersionEdit,
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "saveCellEditEvent",
      this.handleLiveVersionEdit,
      false
    );
    this.context.updateVersionRow([]);
  }

  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }

  displayInAppsValueSetter = params => {
    const { newValue, data } = params;
    if (!newValue || !data || newValue === data.status) {
      return false;
    }
    data.status = newValue;
    this.updateStatus(data.version, newValue);
    return true;
  };

  dateFormatter(params) {
    const { value } = params;
    if (value) {
      //  Format date string as June 15, 2007 at 2:05am (Central Daylight Time)
      return formatDateTimezone(value, true, "en_US");
    } else {
      return "-";
    }
  }

  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: "Version",
        headerClass: "ag-text-header",
        field: "version",
        minWidth: 80,
        maxWidth: 100
      },
      {
        headerName: "Create Time ",
        field: "createTime",
        headerClass: "ag-text-header",
        valueFormatter: this.dateFormatter
      },
      {
        headerName: "Motor Schema",
        field: "motorSchema",
        headerClass: "ag-text-header"
      },
      {
        headerName: "Status",
        field: "status",
        headerClass: "ag-text-header",
        // cellClass: "editable-caret-cell",
        editable: true,
        valueSetter: this.displayInAppsValueSetter,
        cellClassRules: {
          "editable-caret-cell"(params) {
            const { field } = params.colDef;
            if (field === "status") {
              const { data } = params;
              const { liveFlag } = data;
              if (data && !liveFlag && editableStatuses.includes(data.status)) {
                return true;
              }
            }
          }
        },
        enableRowGroup: false,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: false,
          selectAllOnMiniFilter: true, // since version 22.x, the Set Filter param is no longer used
          newRowsAction: "keep",
          buttons: ["clear"]
        },
        cellEditorSelector(params) {
          const { liveFlag, status } = params.data;
          if (liveFlag || status === "IN-PROGRESS" || status === "DELETED") {
            params.api.stopEditing();
            return null;
          }
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: editableStatusesPlusDeleted
            }
          };
        },
        refData: VersionStatusMap,
        width: 110,
        minWidth: 190
      },
      {
        headerName: "Live",
        field: "liveFlag",
        valueFormatter: boolToStringFormatter,
        cellRendererFramework: CheckboxCell,
        cellRendererParams: {
          field: "liveFlag"
        },
        hide: false,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        editable: false,
        enableRowGroup: false,
        maxWidth: 150,
        minWidth: 150
      },
      {
        headerName: "Created By",
        field: "createUser",
        headerClass: "ag-text-header",
        hide: true
      },
      {
        headerName: "Last Modified By",
        field: "modUser",
        headerClass: "ag-text-header",
        hide: true
      },
      {
        headerName: "Modified Time",
        field: "modTime",
        headerClass: "ag-text-header",
        valueFormatter: this.dateFormatter,
        hide: true
      }
    ];
    return baseCols;
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.loadGridData();
    this.getLiveFlag();
    this.setDefaultDealer();
    this.gridApi.closeToolPanel();
    setTimeout(() => {
      this.setAutoHeight();
    }, 0);
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.context.updateVersionRow(selectedRows);
    }
  };
  setAutoHeight = () => {
    this.sizeToFit();
  };
  handleGridSizeChanged = () => {
    this.sizeToFit();
    this.setAutoHeight();
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
    }
  };
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  getRowNodeId(data) {
    return data.version;
  }
  loadGridData() {
    // Point to static Json file
    const restUrl = "opsadmin/rest-db/getValues/versions";

    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data) {
          console.log(data);
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        hideBodyMask();
      }
    );
  }

  getLiveFlag = () => {
    const restUrl = "opsadmin/rest-db/getRow/settings/liveVersion";

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data && data.length) {
          const { keyName, value } = data[0];
          if (keyName === "liveVersion") {
            this.setState(
              {
                liveVersion: value
              },
              () => {
                this.loadGridData();
              }
            );
          }
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };

  setLiveFlag = liveVersion => {
    const restUrl = "opsadmin/rest-db/updateTableData/settings";

    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: { id: "liveVersion", value: liveVersion }
      },
      data => {
        const { rowData } = this.state;
        rowData.forEach(v => {
          v.liveFlag = isSameValue(liveVersion, v.version);
        });
        this.setState(
          {
            rowData,
            liveVersion
          },
          () => {
            this.gridApi.setRowData(rowData);
            // this.gridApi.refreshCells({ force: true });
          }
        );
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };

  handleLiveVersionEdit = event => {
    event.preventDefault();
    const { data, field, value } = event.detail;
    if (field === "liveFlag" && value) {
      const { version } = data;
      this.setLiveFlag(version);
    }
  };

  setDefaultDealer = () => {
    const restUrl = "opsadmin/rest-db/getRow/settings/testDealers";

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data && data.length) {
          const result = data[0].value;
          this.setState({
            inputTestDealer: result,
            lastInputTestDealer: result
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };

  getVersionById = version => {
    const restUrl = `opsadmin/rest-db/getRow/versions/${version}`;

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data && data.length && data[0].modTime) {
          const updatedData = this.state.rowData.map(v =>
            v.version === version ? { ...v, modTime: data[0].modTime } : v
          );

          if (updatedData && updatedData.length) {
            this.setState({ rowData: updatedData });
          }
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching versiion.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };

  updateState(data) {
    const versions = Array.isArray(data) ? data : [];
    versions.sort((a, b) => (a.version < b.version ? 1 : -1));
    const { liveVersion } = this.state;
    versions.forEach(v => {
      v.liveFlag = isSameValue(v.version, liveVersion);
    });
    this.setState(
      {
        rowData: versions
      },
      () => {
        setTimeout(() => {
          this.sizeToFit();
        }, 0);
        this.setAutoHeight();
      }
    );
  }
  onChangeMotor = (cxEvent, isValid) => {
    const { value } = cxEvent.target;
    this.setState({
      motorSelected: value
    });
  };

  changeTestDealer = e => {
    const { value } = e.target;
    this.setState({ inputTestDealer: value });
  };

  isVersionCreationPending() {
    const { rowData } = this.state;
    if (!rowData || !rowData.length) {
      return false;
    }
    let latestVerson = 0;
    let latestIndex = 0;
    rowData.forEach((v, index) => {
      if (v.version > latestVerson) {
        latestVerson = v.version;
        latestIndex = index;
      }
    });
    return rowData[latestIndex].status === "IN-PROGRESS";
  }

  saveNewVersion() {
    if (this.isVersionCreationPending()) {
      toast.warning(
        "A Global Operations Versioning has already been in progress. Please create a version later.",
        {
          closeOnClick: true
        }
      );
      return;
    }

    const restAPI = "/opsadmin/version/createVersion";
    const motorSchema = this.state.motorSelected;
    const createUser = this.context.userName;
    // const modUser = this.context.userName;

    makeSecureRestApi(
      {
        url: restAPI,
        method: "post",
        data: {
          motorSchema,
          // status: "IN-PROGRESS",
          createUser,
          // modUser,
          userComments: ""
        }
      },
      response => {
        if (response && Array.isArray(response) && response.length !== 0) {
          const { rowData } = this.state;
          rowData.push(response[0]);
          const res = this.gridApi.applyTransaction({
            add: response,
            addIndex: 0
          });
          const rowNode = this.gridApi.getRowNode(response[0].version);
          rowNode.setSelected(true);
          // console.log(this.gridApi, res.add);
          // this.gridApi.selectIndex(res.add[0].rowIndex);
          this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
          toast.info(
            "Global Operations Versioning is in progress. Please check back later.",
            {
              closeOnClick: true
            }
          );
        }

        closeCreateVersionPopover();
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "Error while saving changes";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  loadMotorSchema() {
    const restUrl = "/opsadmin/rest-db/getValues/motor";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        headers
      },
      response => {
        const motorSchema = [];
        if (response) {
          const datalist = response;
          if (datalist && datalist.length > 0) {
            datalist.forEach(m => {
              if (m.status === "READY") {
                m.label = m.schemaName;
                m.value = m.schemaName;
                motorSchema.push(m);
              }
            });
          }
          motorSchema.sort((a, b) => {
            const date1 = new Date(a.createTime);
            const date2 = new Date(b.createTime);
            return date1 < date2 ? 1 : -1;
          });
          // console.log("Motor Schema", motorSchema);
        }
        this.setState({
          motorSchema,
          motorSelected: motorSchema.length !== 0 ? motorSchema[0].value : ""
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  updateTestDealer = () => {
    const restUrl = "opsadmin/rest-db/updateTableData/settings";
    const { inputTestDealer } = this.state;
    const { userName, localeStrings } = this.context;

    const data = {
      id: "testDealers",
      value: inputTestDealer,
      modUser: userName,
      modTime: "now"
    };
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      data => {
        if (data) {
          this.setState({ lastInputTestDealer: inputTestDealer });
          toast.success(localeStrings["xmm.portal.common.successful_save"]);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg);
      }
    );
  };

  onBlurTestDealer = () => {
    if (this.state.lastInputTestDealer !== this.state.inputTestDealer) {
      this.updateTestDealer();
    }
  };

  updateStatus = (version, status) => {
    const restUrl = "opsadmin/version/updateVersion";
    const { userName, localeStrings } = this.context;
    const params = {
      id: version + "",
      status,
      modUser: userName,
      modTime: "now"
    };
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: params
      },
      data => {
        if (data) {
          this.getVersionById(version);
          toast.success(localeStrings["xmm.portal.common.successful_save"]);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while updating data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };

  render() {
    const { motorSelected, motorSchema } = this.state;
    const gridClassName = "ag-grid-container ag-theme-balham";
    const tooltipInfo = (
      <div>
        <p className="xmm-popover-text">
          {
            this.context.localeStrings[
              "xmmadmin.portal.version.create-version-tip"
            ]
          }
        </p>
      </div>
    );
    const createVersionPopover = (
      <div>
        <SelectInput
          htmlId="selectInputMotorSchema"
          label="Motor Schema"
          name="selectInputMotorSchema"
          displayDeselectOption={false}
          disabled={motorSchema.length === 0}
          className="xmm-md-input"
          value={motorSelected}
          options={motorSchema}
          onChange={this.onChangeMotor}
        />
        <Button
          htmlId="createNewVersion"
          buttonStyle="secondary"
          size="small"
          disabled={motorSelected === ""}
          block
          onClick={this.saveNewVersion}
        >
          {"Create Version"}
        </Button>
      </div>
    );

    const tooltipDealerTestInfo = (
      <div>
        <p className="xmm-popover-text">
          {
            this.context.localeStrings[
              "xmmadmin.portal.version.dealer_test_tip"
            ]
          }
        </p>
      </div>
    );

    const header = (
      <React.Fragment>
        <div className="content-header">
          <div className="xmm-main-title">
            <h3>{this.state.pageTitle}</h3>
          </div>
        </div>
        <div className="content-header">
          <div>
            <Popover
              popoverContent={createVersionPopover}
              position="right"
              trigger={["click", "outsideClick"]}
            >
              <Button htmlId="createVersion" buttonStyle="primary">
                {"Create Version"}
              </Button>
            </Popover>
            <Popover
              popoverContent={tooltipInfo}
              position="right"
              trigger={["click", "outsideClick"]}
            >
              <IconInfoOutline className="info-blue" />
            </Popover>
          </div>
        </div>
      </React.Fragment>
    );
    const gridWidget = (
      <div id="grid-wrapper">
        <div id="versionGrid" className={gridClassName}>
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressMenuHide={false}
            // suppressContextMenu={true}
            getRowNodeId={this.getRowNodeId}
            rowData={this.state.rowData}
            rowSelection="single"
            singleClickEdit={true}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            statusBar={this.state.statusBar}
            sideBar={this.state.sideBar}
            // multiSortKey={this.state.multiSortKey}
            enableRangeSelection={true}
            enableCellTextSelection={false}
            enableBrowserTooltips={true}
            // rowHeight={30}
            onCellValueChanged={this.onCellValueChanged}
            onFilterChanged={this.onFilterChanged}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.handleGridSizeChanged}
            onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            domLayout={this.state.domLayout}
          />
        </div>
      </div>
    );

    const footer = (
      <div>
        <br />
        <div className="">
          {"Global Operations Test Dealers  "}
          <Popover
            popoverContent={tooltipDealerTestInfo}
            position="right"
            trigger={["click", "outsideClick"]}
          >
            <IconInfoOutline className="info-blue" />
          </Popover>
          <TextInput
            placeholder=""
            onBlur={() => this.onBlurTestDealer()}
            onChange={e => this.changeTestDealer(e)}
            value={this.state.inputTestDealer}
          />
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {header}
        {gridWidget}
        {footer}
      </React.Fragment>
    );
  }
}

export default MainPage;

MainPage.propTypes = {};

const editableStatuses = ["NOT-READY", "IN-TESTING", "READY"];
const editableStatusesPlusDeleted = [
  "NOT-READY",
  "IN-TESTING",
  "READY",
  "DELETED"
];

// Simulate click function
function closeCreateVersionPopover() {
  document.querySelector("#createVersion").click();
}
