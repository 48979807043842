/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import LaborGrid from "./LaborGrid";
import PartsGrid from "./PartsGrid";
import VehicleAttributeSelector from "./VehicleAttributeSelector";
// import DealerOperationsTable from "../components/dealer/DealerOperationsTable";
import PositionExclusion from "./data/PositionExclusion.json";
import {
  hasAllVehicleAttributesSelected,
  hasSelectableVehicleAttributes
} from "./utils/helper";

class ResultsPane extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { partsAndLabor } = nextProps;
    if (partsAndLabor && partsAndLabor !== prevState.partsAndLabor) {
      // const { dealerPublishedCatalog, parts } = partsAndLabor;
      // const selectedParts = dealerPublishedCatalog ? parts : [];
      let hideLaborGrid = !showLaborAndPartsGrids(partsAndLabor);
      const { selectableVehicleAttributes } = partsAndLabor;
      const showVehAttrSelector = hasSelectableVehicleAttributes(
        selectableVehicleAttributes
      );
      if (showVehAttrSelector) {
        hideLaborGrid = true;
      }

      return {
        showVehAttrSelector,
        hideLaborGrid,
        partsAndLabor,
        filteredParts:
          partsAndLabor && partsAndLabor.parts ? [...partsAndLabor.parts] : [],
        laborSelected: false
        // selectedParts
      };
    }
    return null;
  }
  constructor(props) {
    super(props);
    const { partsAndLabor } = props;
    this.laborGridRef = React.createRef();
    this.partsGridRef = React.createRef();
    this.selectLaborRow = this.selectLaborRow.bind(this);
    this.selectPartsRow = this.selectPartsRow.bind(this);
    this.onVehicleAttributeSelectorChange = this.onVehicleAttributeSelectorChange.bind(
      this
    );
    const filteredParts =
      partsAndLabor && partsAndLabor.parts ? [...partsAndLabor.parts] : [];
    // const { dealerPublishedCatalog, parts } = partsAndLabor || {};
    // const selectedParts = dealerPublishedCatalog ? parts : [];
    let hideLaborGrid = !showLaborAndPartsGrids(partsAndLabor);
    const showVehAttrSelector =
      partsAndLabor &&
      hasSelectableVehicleAttributes(partsAndLabor.selectableVehicleAttributes);
    if (showVehAttrSelector) {
      hideLaborGrid = !hasAllVehicleAttributesSelected(
        partsAndLabor.selectedVehicleAttributeMap,
        partsAndLabor.selectableVehicleAttributes.length
      );
    }
    this.state = {
      showVehAttrSelector,
      hideLaborGrid,
      partsAndLabor,
      filteredParts,
      selectedLabors: props.selectedLabors || [],
      selectedParts: props.selectedParts || [],
      laborSelected: false,
      laborAppPositions: []
    };
  }

  refreshPartsGrid() {
    this.partsGridRef &&
      this.partsGridRef.current &&
      this.partsGridRef.current.refreshGrid();
  }

  getNonOptionalPartsAndFluds = filteredParts => {
    const nonOptionalParts = filteredParts.filter(
      p => p.motorPartName && p.relationship !== "Related/Optional"
    );
    const nonOptionalFluids = filteredParts.filter(
      p => p.motorFluidName && p.relationship !== "Related/Optional"
    );
    return {
      nonOptionalParts,
      nonOptionalFluids
    };
  };
  getSelectables = (selectableItems, laborAppPositions) => {
    const positions = laborAppPositions.filter(
      position => position && position !== "N/A"
    );
    const selectables = selectableItems.filter(p => {
      if (positions.length !== 0) {
        return (
          !p.position || p.position === "N/A" || positions.includes(p.position)
        );
      }
      return true;
    });

    const selectorParts = [];
    const selectedByPosition = {};
    selectables.forEach(row => {
      if (row.position in selectedByPosition) {
        selectedByPosition[row.position].count++;
        selectedByPosition[row.position].id = row.id;
      } else {
        selectedByPosition[row.position] = { count: 1, row };
      }
    });
    for (const partSelected of Object.values(selectedByPosition)) {
      if (partSelected.count === 1) {
        selectorParts.push(partSelected.row);
      }
    }

    return selectorParts;
  };
  selectLaborRow = selectedRows => {
    const laborSelected = selectedRows.length !== 0;
    const laborAppPositions = this.getPositions(selectedRows);
    const { partsAndLabor } = this.state;
    const { dealerPublishedCatalog } = partsAndLabor;
    let selectedParts = null;
    if (dealerPublishedCatalog) {
      this.setState({
        laborSelected,
        laborAppPositions,
        // filteredParts,
        selectedParts: partsAndLabor.parts
      });
    } else {
      const filteredParts = this.getFilteredParts(
        laborAppPositions,
        partsAndLabor.parts
      );
      selectedParts = filteredParts.filter(item => {
        return item.manuallySelected;
      });
      if (filteredParts.length === 1) {
        filteredParts[0].manuallySelected = true;
      }
      this.setState({
        laborSelected,
        laborAppPositions,
        filteredParts,
        selectedParts
      });
    }
    this.props.selectGridRow(selectedRows, "labor", selectedParts);
  };
  selectPartsRow = selectedParts => {
    this.props.selectGridRow(selectedParts, "parts");
    this.setState({ selectedParts });
  };
  getPositions = laborApps => {
    const laborAppPositions = [];
    laborApps.forEach(l => {
      if (l.position) {
        laborAppPositions.push(l.position);
      }
    });
    return laborAppPositions;
  };
  isPositionExcluded = (excludedPositions, position) => {
    for (const s of excludedPositions) {
      if (s.toLowerCase() === position.toLowerCase()) {
        return true;
      }
    }
    return false;
  };
  getFilteredParts = (laborAppPositions, parts) => {
    if (laborAppPositions.length === 0) {
      return [...parts];
    } else {
      const filteredParts = parts.filter(p => {
        let included = false;
        if (p.position) {
          for (let i = 0; i < laborAppPositions.length; i++) {
            const laborAppPosition = laborAppPositions[i];
            const excludedPositions = PositionExclusion[laborAppPosition];
            if (
              !excludedPositions ||
              // p.position === "N/A" ||
              !this.isPositionExcluded(excludedPositions, p.position)
            ) {
              included = true;
              break;
            }
          }
        } else {
          included = true;
        }
        return included;
      });
      return filteredParts;
    }
  };
  onVehicleAttributeSelectorChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    console.log(name, value);
    const { hideLaborGrid, partsAndLabor } = this.state;
    const {
      selectedVehicleAttributeMap,
      selectableVehicleAttributes,
      selectableVehAttrLabors,
      selectableVehAttrParts
    } = partsAndLabor;

    if (!value) {
      delete selectedVehicleAttributeMap[name];
      if (!hideLaborGrid) {
        this.setState({ hideLaborGrid: true });
      }
      this.props.selectGridRow([], "labor", []);
      return;
    }

    selectedVehicleAttributeMap[name] = value;
    // }
    if (
      hasAllVehicleAttributesSelected(
        selectedVehicleAttributeMap,
        selectableVehicleAttributes.length
      )
    ) {
      const labors = selectableVehAttrLabors.filter(labor => {
        const { vehicleAttributes } = labor;
        if (!vehicleAttributes) {
          return true;
        }
        const vehAttrKeys = Object.keys(vehicleAttributes);
        for (let i = 0; i < vehAttrKeys.length; i++) {
          const key = vehAttrKeys[i];
          if (
            selectedVehicleAttributeMap[key] !== "idk" &&
            selectedVehicleAttributeMap[key] !== vehicleAttributes[key]
          ) {
            return false;
          }
        }
        return true;
      });
      partsAndLabor.labors = labors;
      const parts = selectableVehAttrParts.filter(part => {
        const { vehicleAttributes } = part;
        if (!vehicleAttributes) {
          return true;
        }
        const vehAttrKeys = Object.keys(vehicleAttributes);
        for (let i = 0; i < vehAttrKeys.length; i++) {
          const key = vehAttrKeys[i];
          if (
            selectedVehicleAttributeMap[key] !== "idk" &&
            selectedVehicleAttributeMap[key] !== vehicleAttributes[key]
          ) {
            return false;
          }
        }
        return true;
      });
      partsAndLabor.parts = parts;
      let laborSelected = true;
      if (labors.length === 1) {
        // const laborAppPositions = this.getPositions(labors);
        // const filteredParts = this.getFilteredParts(
        //   laborAppPositions,
        //   partsAndLabor.parts
        // );
        // partsAndLabor.filteredParts = filteredParts;
        this.selectLaborRow(labors);
      } else {
        laborSelected = false;
        this.laborGridRef &&
          this.laborGridRef.current &&
          this.laborGridRef.current.resetSelection();
        this.setState({ selectedLabors: [], laborSelected });
      }
      this.setState({ partsAndLabor, hideLaborGrid: false });
    } else {
      if (!hideLaborGrid) {
        this.setState({ hideLaborGrid: true });
      }
    }
  };

  updatePartsPricingAndInventory(parts) {
    //
  }
  render() {
    let resultSection = null;
    const { disableAllParts } = this.props;
    const {
      showVehAttrSelector,
      hideLaborGrid,
      laborSelected,
      selectedLabors,
      selectedParts,
      partsAndLabor
    } = this.state;

    if (
      partsAndLabor
      // partsAndLabor.operation.operationSource === "GlobalCatalog"
    ) {
      const { filteredParts } = this.state;
      let partsGrid = "";
      if (laborSelected) {
        const { selectedVehicleAttributeMap } = partsAndLabor;
        const additionalColumns = partsAndLabor.partsColumns.filter(column => {
          const { headerName } = column;
          const value = selectedVehicleAttributeMap[headerName];
          return !value || value === "idk";
        });
        partsGrid = (
          <PartsGrid
            ref={this.partsGridRef}
            rowData={filteredParts}
            selectedParts={selectedParts}
            additionalColumns={additionalColumns}
            naNrNssParts={partsAndLabor.naNrNssParts}
            fluidHasQualifiers={partsAndLabor.fluidHasQualifiers}
            includeInspectColumns={this.props.includeInspectColumns}
            disableAllParts={disableAllParts}
            onSelectRow={this.selectPartsRow}
            // optionalPRForFluids={partsAndLabor.optionalPRforFluids}
            // optionalPRForParts={partsAndLabor.optionalPRForParts}
          />
        );
      }
      const vehAttrSelector = showVehAttrSelector ? (
        <VehicleAttributeSelector
          partsAndLabor={partsAndLabor}
          onChange={this.onVehicleAttributeSelectorChange}
        />
      ) : (
        ""
      );

      resultSection = (
        <div>
          {vehAttrSelector}
          <div className={`${hideLaborGrid ? "hide" : "ops-results-grid"}`}>
            <LaborGrid
              ref={this.laborGridRef}
              partsAndLabor={partsAndLabor}
              showHeaderCheckbox={!partsAndLabor.dealerPublishedCatalog}
              selectedLabors={selectedLabors}
              rowData={partsAndLabor.labors}
              additionalColumns={partsAndLabor.laborsColumns}
              onSelectRow={this.selectLaborRow}
            />
            {partsGrid}
          </div>
        </div>
      );
    }
    return <React.Fragment>{resultSection}</React.Fragment>;
  }
}
export default ResultsPane;

ResultsPane.propTypes = {
  disableAllParts: PropTypes.bool,
  showHeaderCheckbox: PropTypes.bool,
  includeInspectColumns: PropTypes.bool,
  partsAndLabor: PropTypes.object,
  selectGridRow: PropTypes.func,
  selectedLabors: PropTypes.array,
  selectedParts: PropTypes.array
};

ResultsPane.defaultProps = {
  disableAllParts: false,
  showHeaderCheckbox: true,
  includeInspectColumns: true,
  selectedLabors: [],
  selectedParts: []
};

function showLaborAndPartsGrids(partsAndLabor) {
  if (partsAndLabor && partsAndLabor.labors && partsAndLabor.labors.length) {
    for (const labor of partsAndLabor.labors) {
      if (labor && labor.baseEwtMinutes) {
        return true;
      }
    }
  }
  return false;
}
