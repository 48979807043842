/* eslint-disable no-console */
import React, { Component } from "react";
import { AppContext } from "../../../components/app-context";
import { xlate } from "../../../commonUtil/i18n/locales";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SearchableSelect from "@cx/ui/SearchableSelect";
import Button from "@cx/ui/Button";
import { toast } from "@cx/ui/Toast";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import { hasUserRole } from "../../../role/roleUtil";
import PackagesGrid from "./PackagesGrid";
import Confirmation from "../../../commonUtil/dialog/Confirmation";
import { launchCatalogManager } from "../../reusable/launch";

class PushPackageOverrides extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.onSelectVariant = this.onSelectVariant.bind(this);
    this.onSelectDealer = this.onSelectDealer.bind(this);
    this.handlePushAction = this.handlePushAction.bind(this);
    this.initializeLocaleValues();
    const { role } = context;
    const hasRole = hasUserRole("dealerAdmin", role);
    this.gridRef = React.createRef();
    this.packages = [];
    this.state = {
      hasRole,
      dealer: null,
      search: {
        make: "",
        variant: "",
        dealerCode: ""
      },
      variants: [],
      dealerCodes: [],
      totalPackagesMsg: "",
      selectionlist: [],
      menuTypes: [],
      showGrid: false,
      formReady: false,
      errors: {},
      showPushModal: false,
      pushConfirmMessage: ""
    };
  }
  componentDidMount() {
    this.loadVariants();
  }

  initializeLocaleValues() {
    this.pageTitle = xlate("xmmadmin.portal.common.pushpackages_lbl");
  }
  onSelectVariant = (cxEvent, isValid, domEvent) => {
    const { search } = this.state;
    const value = cxEvent.target.value;
    const optionValue =
      Array.isArray(value) && value.length !== 0 ? value[0].value : "";
    const makeVal = optionValue.split("/");
    const make = makeVal && makeVal.length === 2 ? makeVal[0].trim() : "";
    const variant = makeVal && makeVal.length === 2 ? makeVal[1].trim() : "";

    if (search.make !== make || search.variant !== variant) {
      search.make = make;
      search.variant = variant;
      this.setState(
        {
          search,
          formReady: false,
          dealerCodes: [],
          packages: [],
          selectionlist: []
        },
        () => {
          console.log("variant select", optionValue, search);
          this.reset();
          this.loadPackageTemplates();
          this.loadDealers();
        }
      );
    }
  };
  reset() {
    this.setState({
      formReady: false,
      dealerCodes: [],
      totalPackagesMsg: ""
    });
  }
  onSelectDealer = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const { search } = this.state;
    const { dealerCode } = search;
    const dealer = Array.isArray(value) && value.length !== 0 ? value[0] : null;
    const optionValue = dealer ? dealer.value : "";
    if (optionValue !== dealerCode) {
      search.dealerCode = optionValue;
      this.setState(
        {
          dealer,
          search
        },
        () => {
          if (optionValue !== "") {
            this.loadDealerPackages();
          }
        }
      );
    }
  };

  loadDealerPackages() {
    const { search } = this.state;
    const restUrl =
      "/opsadmin/proxyapi/ddsproxy/rest/proc/getEnhancedDealerPackages";
    const params = {
      requestType: "",
      authId: "",
      dealerCode: search.dealerCode,
      make: search.make,
      variant: search.variant,
      locale: "en_US",
      includeEnhanced: 1,
      showTemplate: 0,
      effectiveDate: "",
      menuTypeId: ""
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params,
        headers
      },
      response => {
        if (response) {
          const packagesLen = response.length;
          const totalPackagesMsg =
            packagesLen === 0
              ? ""
              : `Dealer already has ${packagesLen} Package Overrides for selected Make/Variant.`;
          this.setState({ totalPackagesMsg });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  loadPackageTemplates() {
    this.packages = null;
    this.gridRef &&
      this.gridRef.current &&
      this.gridRef.current.showLoadingOverlay();

    const { search } = this.state;
    const restUrl =
      "/opsadmin/proxyapi/ddsproxy/rest/proc/findPackageTemplates";
    const postObj = {
      requestType: "findPackageTemplates",
      authId: "",
      make: search.make,
      variant: search.variant,
      locale: "en_US"
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: postObj,
        params: {},
        headers
      },
      data => {
        let menuTypes = [];
        const menuTypeMap = {};
        const datalist =
          !data ||
          !data.response ||
          !data.response.packages ||
          !data.response.packages.package
            ? []
            : data.response.packages.package;
        this.packages = datalist;
        if (datalist.length > 0) {
          // get unique menutypes data
          datalist.forEach(
            menuType => (menuTypeMap[menuType.menuTypeId.toString()] = menuType)
          );
          const menuList = Object.values(menuTypeMap);
          menuTypes = menuList.map(m => {
            return {
              value: m.menuTypeId.toString(),
              label: m.menuTypeDescription
            };
          });
          menuTypes.splice(0, 0, {
            value: "ALL",
            label: "All Menus"
          });

          // console.log(menuTypeMap, menuTypes);
          this.gridRef &&
            this.gridRef.current &&
            this.gridRef.current.hideOverlay();
        } else {
          this.gridRef &&
            this.gridRef.current &&
            this.gridRef.current.showNoRowsOverlay();
        }
        this.setState({
          menuTypes,
          formReady: true,
          showGrid: true
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridRef &&
          this.gridRef.current &&
          this.gridRef.current.showNoRowsOverlay();
      }
    );
  }

  loadVariants() {
    const restUrl =
      "/opsadmin/proxyapi/ddsproxy/rest/table/getEnhancedVariants";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: {
          enhancedPricing: 1
        },
        headers
      },
      response => {
        const variants = [];
        const makeVariantMap = {};
        if (response) {
          const datalist = response;
          if (datalist && datalist.length > 0) {
            datalist.forEach(m => {
              if (m.enhancedPricing === 1) {
                const newValue = m.make + " / " + m.variant;
                m.label = newValue;
                m.value = newValue;
                variants.push(m);
              }
              makeVariantMap[m.make] = m;
            });
          }
          console.log("Variants", variants);
        }
        this.setState({
          variants,
          makeVariantMap
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  loadDealers() {
    const { search } = this.state;
    const restUrl =
      "/opsadmin/proxyapi/ddsproxy/rest/table/getEnhancedVariantDealers";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: {
          make: search.make,
          variant: search.variant
        },
        headers
      },
      response => {
        const dealerCodes = [];
        if (response) {
          const datalist = response;
          if (datalist && datalist.length > 0) {
            datalist.forEach(m => {
              if (m.dealerCode) {
                const newValue = m.dealerName + " (" + m.dealerCode + ")";
                m.label = newValue;
                m.value = m.dealerCode;
                dealerCodes.push(m);
              }
            });
            dealerCodes.sort((d1, d2) => {
              const a = d1.dealerName.toLowerCase();
              const b = d2.dealerName.toLowerCase();
              return a === b ? 0 : a > b ? 1 : -1;
            });
          }
          console.log("Dealers", dealerCodes);
        }
        this.setState({
          dealerCodes
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  handlePushAction = event => {
    if (event) {
      event.preventDefault();
    }
    const { dealer } = this.state;
    const msg = `Do you want to push the selected package override templates to ${
      dealer.dealerName
    }?`;
    this.setState({
      showPushModal: true,
      pushConfirmMessage: msg
    });
  };
  closePushModal = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showPushModal: false,
      pushConfirmMessage: ""
    });
  };
  pushPackageTemplate = event => {
    if (event) {
      event.preventDefault();
    }

    this.setState({ showPushModal: false });

    const payload = this.getPayload();
    if (payload && Object.keys(payload).length === 0) {
      return;
    }

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restAPI =
      "/opsadmin/proxyapi/ddsproxy/rest/proc/copyTemplateDealerPackages";

    makeSecureRestApi(
      {
        url: restAPI,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      response => {
        this.onSucessCallback(response);
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "Error while saving changes";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };
  onSucessCallback = data => {
    const { statusCode, statusMessage } = data.response;
    console.log(data);
    if (statusCode === 0) {
      toast.success(statusMessage);
    } else {
      toast.error(statusMessage);
    }
    this.loadDealerPackages();
  };
  getPayload() {
    const { search, selectionlist } = this.state;
    let packageIds = [];
    if (selectionlist.length > 0) {
      packageIds = selectionlist.map(packages => packages.id.toString());
      // selectionlist.forEach((pkg, idx) => (list[idx] = pkg.id.toString()));
    }
    const payload = {
      requestType: "copyTemplateDealerPackages",
      authId: "",
      make: search.make,
      variant: search.variant,
      packages: packageIds,
      targetDealers: [search.dealerCode]
    };
    console.log(payload, packageIds);
    return payload;
  }
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = selectionlist => {
    this.setState({
      selectionlist
    });
  };
  render() {
    const {
      hasRole,
      variants,
      dealerCodes,
      search,
      selectionlist,
      formReady
    } = this.state;
    const maskStyle = formReady
      ? "xmm-push-package-grid-container"
      : "xmm-push-package-grid-container xmm-mask";
    const markDisabled =
      search.make === "" ||
      search.variant === "" ||
      search.dealerCode === "" ||
      selectionlist.length === 0
        ? true
        : false;
    const disabled = !hasRole;
    const totalCount =
      selectionlist.length === 0 ? "" : `( ${selectionlist.length} )`;

    const pushConfirmationDialog = (
      <Confirmation
        htmlId="pushPackagesDialog"
        message={this.state.pushConfirmMessage}
        proceedButtonStyle="danger"
        show={this.state.showPushModal}
        actionFunction={this.pushPackageTemplate}
        closeDialog={this.closePushModal}
      />
    );

    return (
      <div disabled={disabled}>
        <h3>{this.pageTitle}</h3>
        <Grid>
          <Row>
            <Col xs={12} md={12} className="full-col">
              <div className="xmm-push-search-container">
                <Col md={3} sm={3}>
                  <div className="xmm-dealer-select">
                    <SearchableSelect
                      className="dealer-div"
                      htmlId="variant"
                      name="variant"
                      label={"Variant"}
                      placeholder={"Select"}
                      disabled={variants.length === 0}
                      enableMultiSelect={false}
                      maxHeight={200}
                      maxResults={1000}
                      value=""
                      options={variants}
                      onChange={this.onSelectVariant}
                    />
                    <div className="launch-icon" />
                  </div>
                  <div className="xmm-dealer-select">
                    <SearchableSelect
                      className="dealer-div"
                      htmlId="dealerCode"
                      name="dealerCode"
                      label={"Push To"}
                      placeholder={"Select"}
                      disabled={dealerCodes.length === 0}
                      enableMultiSelect={false}
                      maxHeight={200}
                      maxResults={1000}
                      value=""
                      options={dealerCodes}
                      onChange={this.onSelectDealer}
                    />
                    <div
                      className="launch-icon"
                      disabled={search.dealerCode === ""}
                      onClick={() => {
                        const { dealer } = this.state;
                        launchCatalogManager(dealer.webKey);
                      }}
                    >
                      <img
                        src={require("../../../images/launch_icon.svg")}
                        alt="VinSolutions logo"
                      />
                    </div>
                  </div>

                  <Button
                    htmlId="btnPushPackages"
                    disabled={markDisabled}
                    onClick={this.handlePushAction}
                  >
                    Push Templates to Dealer {totalCount}
                  </Button>
                  <div className="xmm-modal-help-text">
                    {this.state.totalPackagesMsg}
                  </div>
                </Col>
              </div>

              <Col md={9} sm={9} className={maskStyle}>
                <PackagesGrid
                  ref={this.gridRef}
                  showGrid={this.state.showGrid}
                  packages={this.packages}
                  menuTypes={this.state.menuTypes}
                  gridSelectionsFunc={this.handleSelectionChanged}
                />
              </Col>
            </Col>
          </Row>
        </Grid>
        {pushConfirmationDialog}
      </div>
    );
  }
}

export default PushPackageOverrides;
