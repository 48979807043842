/* eslint-disable react/jsx-key */
/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import CustomLoadingOverlay from "./reusable/loadingmask/CustomLoadingOverlay";
import { priceFormatter, toEmptyStringIfUndefined } from "./utils/helper";
import NumericEditor from "./editors/NumericEditor";
import { AgGridReact } from "ag-grid-react";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import IconWarning from "@cx/ui/Icons/IconWarning";
import Badge from "@cx/ui/Badge";
import Popover from "@cx/ui/Popover";
import Tooltip from "@cx/ui/Tooltip";
// import Table from "@cx/ui/Table";
import SimpleTable from "@cx/ui/SimpleTable";

class PartsGrid extends React.Component {
  // static contextType = PreviewContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.rowData !== prevState.rowData ||
      nextProps.additionalColumns !== prevState.additionalColumns
    ) {
      const {
        disableAllParts,
        rowData,
        selectedParts,
        naNrNssParts,
        additionalColumns,
        includeInspectColumns,
        fluidHasQualifiers
      } = nextProps;
      const fluidsAndParts = prevState.parentHandle.getRowDataWithSupersessionHistory(
        rowData
      );
      const columnDefs = prevState.getColumnList(
        fluidsAndParts,
        disableAllParts,
        additionalColumns,
        prevState.parentHandle,
        fluidHasQualifiers,
        includeInspectColumns
      );
      setTimeout(() => {
        prevState.adjustGridColumns();
        prevState.parentHandle.preselectRow();
      }, 200);
      return {
        disableAllParts,
        rowData: fluidsAndParts,
        selectedParts,
        selectionlist: selectedParts,
        naNrNssParts,
        additionalColumns,
        columnDefs
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.onGridReady = this.onGridReady.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.adjustGridColumns = this.adjustGridColumns.bind(this);
    this.sizeToFit = this.sizeToFit.bind(this);
    this.setAutoHeight = this.setAutoHeight.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);

    const {
      disableAllParts,
      rowData,
      naNrNssParts,
      additionalColumns,
      selectedParts
    } = props;

    const fluidAndParts = this.getRowDataWithSupersessionHistory(rowData);
    const gridOptions = {
      disableAllParts,
      // other state props
      pageTitle: "Parts",
      // ag-grid props
      rowData: fluidAndParts,
      naNrNssParts,
      additionalColumns: additionalColumns ? additionalColumns : [],
      selectionlist: selectedParts,
      columnDefs: this.getColumnList(
        fluidAndParts,
        disableAllParts,
        additionalColumns,
        this,
        props.fluidHasQualifiers,
        props.includeInspectColumns
      ),
      defaultColDef: {
        // cellClass: "xmm-wrap-cell",
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        filter: "agTextColumnFilter",
        filterParams: {
          suppressMiniFilter: false,
          buttons: ["clear"]
        },
        menuTabs: ["filterMenuTab"],
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        floatingFilter: false, // true - enable column header filters
        rowGroup: false
      },
      multiSortKey: "ctrl",
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
        numericEditor: NumericEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },

      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },

      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      rowSelection: "multiple", // allows multiple row selections with check column
      isRowSelectable(rowNode) {
        return true; // to see checkbox
      },
      columnTypes: {
        nonEditableColumn: { editable: false },
        numberColumn: {
          width: 160,
          filter: "agNumberColumnFilter"
        },
        priceColumn: {
          editable: false,
          valueFormatter: priceFormatter,
          filter: "agNumberColumnFilter",
          cellClass: "xmm-wrap-cell xmm-grid-price",
          cellStyle: {
            color: "black",
            textAlign: "right"
          }
        },
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      domLayout: "autoHeight",
      // popupParent: document.body,
      sideBar: false,
      getColumnList: this.getColumnList,
      adjustGridColumns: this.adjustGridColumns,
      parentHandle: this
    };
    this.state = gridOptions;
  }
  getColumnList(
    fluidsAndParts,
    disableAllParts,
    additionalColumns,
    parentHandle,
    fluidHasQualifiers,
    includeInspectColumns
  ) {
    const optionalParts = fluidsAndParts.filter(
      p => p.relationship === "Related/Optional"
    );
    const relationshipOrder = {
      Primary: "0",
      Recommended: "1",
      "Related/Optional": "2"
    };
    const optionalPartsFlag = optionalParts.length !== 0;
    const { rowData } = parentHandle.props;
    const { dealerPublishedCatalog } = rowData.length ? rowData[0] : {};
    const columns = [
      {
        headerName: "",
        headerCheckboxSelection:
          rowData.length &&
          rowData[0] &&
          rowData[0].hasOwnProperty("dealerPublishedCatalog")
            ? !rowData[0].dealerPublishedCatalog
            : !disableAllParts,
        headerCheckboxSelectionFilteredOnly: !disableAllParts,
        checkboxSelection: params => {
          const { oePartNumber } = params.data;
          return !ExcludedParts.includes(oePartNumber);
        },
        pinned: "left",
        field: "checked",
        type: "actionColumn",
        cellStyle: params => {
          return disableAllParts || params.data.dealerPublishedCatalog === 1
            ? { "pointer-events": "none", opacity: "0.4" }
            : "";
        },
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true, // hide item in sidebar.columns
        maxWidth: 37,
        minWidth: 37,
        width: 37
      },
      {
        headerName: "Qty",
        headerClass: "ag-text-header",
        pinned: "left",
        field: "quantity",
        editable: dealerPublishedCatalog ? false : !disableAllParts,
        // cellEditor: "numericEditor",
        cellEditorSelector(params) {
          const { data } = params;
          if (data) {
            const { oePartNumber } = data;
            if (!oePartNumber || !ExcludedParts.includes(oePartNumber)) {
              return {
                component: "numericEditor"
              };
            }
            params.api.stopEditing();
            return null;
          }
        },
        cellEditorParams(params) {
          const { data } = params;
          let allowDecimals = false;
          let maxLength = -1;
          const maxValue = 100;
          if (data) {
            if (data.hasOwnProperty("motorPartName")) {
              maxLength = 3;
            } else {
              allowDecimals = true;
              maxLength = 4;
            }
          }
          return { parentHandle, allowDecimals, maxLength, maxValue };
        },
        cellStyle: {
          color: "black",
          textAlign: "left"
        },
        valueFormatter: qtyFormatter,
        suppressSizeToFit: true,
        type: "numberColumn",
        maxWidth: 120,
        minWidth: 75,
        width: 75
      },
      {
        headerName: "Description",
        field: "description",
        tooltipField: "description",
        headerClass: "ag-text-header",
        cellRendererFramework: descriptionCellRenderer,
        // maxWidth: 500,
        minWidth: 200
        // width: 280,
      },
      {
        headerName: "Part Relationship",
        field: "relationship",
        hide: !optionalPartsFlag,
        valueFormatter: relFormatter,
        comparator: (value1, value2) => {
          if (!relationshipOrder[value1] || !relationshipOrder[value2]) {
            if (!relationshipOrder[value1]) {
              return -1;
            }
            return 1;
          } else {
            return relationshipOrder[value1].localeCompare(
              relationshipOrder[value2]
            );
          }
        },
        maxWidth: 150,
        minWidth: 120,
        width: 120
      },
      {
        headerName: "Part Number",
        field: "oePartNumber",
        headerClass: "ag-text-header",
        cellRendererFramework: partNumberCellRenderer,
        suppressSizeToFit: true,
        maxWidth: 200,
        minWidth: 100,
        width: 200
      },
      {
        headerName: "MSRP/item",
        field: "price",
        headerClass: "ag-numeric-header",
        suppressSizeToFit: true,
        type: "priceColumn",
        maxWidth: 110,
        minWidth: 110,
        width: 110
      }
    ];
    if (fluidHasQualifiers) {
      columns.push({
        headerName: "Qualifiers",
        field: "qualifiers",
        headerClass: "ag-text-header",
        tooltipField: "qualifiers",
        // suppressSizeToFit: true,
        // maxWidth: 300,
        // minWidth: 500,
        width: 350
      });
    }
    if (includeInspectColumns) {
      columns.push(
        {
          headerName: "DMS Price",
          field: "dmsPrice",
          headerClass: "ag-numeric-header",
          type: "priceColumn",
          maxWidth: 100,
          minWidth: 100,
          width: 100
        },
        {
          headerName: "Qty on Hand",
          field: "quantityOnHand",
          headerClass: "ag-text-header",
          // cellRendererFramework: instockCellRenderer,
          maxWidth: 80,
          minWidth: 80
        }
      );
    }
    columns.push({
      headerName: "Notes",
      field: "notes",
      headerClass: "ag-text-header",
      cellRendererFramework: noteCellRenderer,
      maxWidth: 80,
      minWidth: 80,
      hide: dealerPublishedCatalog ? true : false
    });
    if (additionalColumns && additionalColumns.length !== 0) {
      additionalColumns.forEach(column => {
        const { field, headerName } = column;
        columns.push({
          headerName,
          field,
          tooltipField: field,
          headerClass: "ag-text-header",
          filter: "agSetColumnFilter",
          // suppressSizeToFit: true
          maxWidth: 150
          // minWidth: 150
          // width: 120
        });
      });
    }
    return columns;
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.adjustGridColumns();
      this.preselectRow();
    }, 0);
    this.gridApi.closeToolPanel();
    this.applySortConfig();
  };
  setAutoHeight = () => {
    // this.gridApi.setDomLayout("autoHeight");
    // document.querySelector("#partsGrid").style.height = "";
    const { domLayout } = this.state;
    const { rowData } = this.props;
    if (rowData) {
      const newDomLayout = rowData.length < 20 ? "autoHeight" : "";
      if (domLayout !== newDomLayout) {
        this.gridApi.setDomLayout(newDomLayout);
        this.setState({ domLayout: newDomLayout });
      }
    }
  };
  sizeToFit = () => {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  };
  handleGridSizeChanged = () => {
    // this.adjustGridColumns();
    this.sizeToFit();
  };
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      const { onSelectRow } = this.props;
      const { rowData } = this.state;
      rowData.forEach(d => {
        d.manuallySelected = selectedRows.includes(d);
      });
      onSelectRow(selectedRows);
      this.setState({ selectionlist: selectedRows });
    }
  };
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  getRowNodeId(data) {
    return data.id;
  }
  adjustGridColumns() {
    this.sizeToFit();
    this.setAutoHeight();
  }
  preselectRow() {
    const { disableAllParts, rowData, selectionlist } = this.state;
    if (disableAllParts || !this.gridApi) {
      return;
    }
    if (rowData && rowData.length === 1) {
      if (rowData[0].manuallySelected) {
        this.gridApi.forEachNode(node => {
          node.setSelected(true);
        });
      }
    } else {
      if (selectionlist && selectionlist.length !== 0) {
        selectionlist.forEach(item => {
          if (item.manuallySelected) {
            const node = this.gridApi.getRowNode(item.id);
            if (node) {
              node.setSelected(true);
            }
          }
        });
      }
    }
  }
  getRowDataWithSupersessionHistory(rowData) {
    if (!rowData) {
      return null;
    }
    rowData.forEach(row => {
      const { oePartNumber, status, supersededFluids, supersededParts } = row;
      if (!row.processedSupersededHistory) {
        row.processedSupersededHistory = true;
        const supersessionHistory = [];
        if (supersededFluids && supersededFluids.length !== 0) {
          supersessionHistory.push(...supersededFluids);
        } else if (supersededParts && supersededParts.length !== 0) {
          supersessionHistory.push(...supersededParts);
        }
        if (supersessionHistory.length !== 0) {
          supersessionHistory.splice(0, 0, { oePartNumber, status });
        }
        row.supersessionHistory = supersessionHistory;
      }
    });
    return rowData;
  }
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "relationship",
        sort: "asc",
        sortIndex: 0
      },
      {
        colId: "description",
        sort: "asc",
        sortIndex: 1
      }
    ];
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    if (params) {
      params.api.refreshCells({ force: true });
    } else {
      this.gridApi && this.gridApi.refreshCells({ force: true });
    }
  }
  renderPageTitle(rowData) {
    let pageTitle = "Parts";
    if (rowData && rowData.length > 0) {
      pageTitle = "Parts (" + rowData.length + ")";
    }
    return pageTitle;
  }
  rendreNaNrNssPopover(naNrNssParts) {
    const posts = naNrNssParts.map((post, index) => {
      let notes = [];
      if (post.notes && post.notes.length > 0) {
        notes = post.notes[0];
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <tr className="ops-ant-table-row" key={`key${index}`}>
          <td>
            <span>{post.description}</span>
          </td>
          <td>
            <span>{post.oePartNumber}</span>
          </td>
          <td>
            <span>{notes}</span>
          </td>
        </tr>
      );
    });
    const main = (
      <div className="ops-na-table-container">
        <table>
          <thead className="ant-table-thead">
            <tr>
              <th>Parts Not Applicable</th>
            </tr>
          </thead>
          <tbody>{posts}</tbody>
        </table>
      </div>
    );
    return main;
  }
  renderNaNrNssIconWithPopover(naNrNssParts) {
    if (!naNrNssParts || naNrNssParts.length === 0) {
      return "";
    }
    const popoverContent = this.rendreNaNrNssPopover(naNrNssParts);
    return (
      <span className="ops-na-parts">
        <Popover
          htmlId="naNrNssLink"
          trigger={["click", "outsideClick"]}
          className="ops-na-parts-popover"
          popoverContent={popoverContent}
          position="right"
        >
          <IconInfoOutline className="info" />
        </Popover>
      </span>
    );
  }
  render() {
    const { rowData, naNrNssParts } = this.state;
    const gridClassname =
      rowData && rowData.length < 20
        ? "ag-grid-container ops-auto-height no-striped-grid ops-scroll-x-hidden ag-theme-balham"
        : "ag-grid-container ops-parts-grid no-striped-grid ops-scroll-x-hidden ag-theme-balham";
    const pageTitle = this.renderPageTitle(rowData);
    const naNrNssPartsIcon = this.renderNaNrNssIconWithPopover(naNrNssParts);
    return (
      <React.Fragment>
        <h4>
          {pageTitle}&nbsp;{naNrNssPartsIcon}
        </h4>
        <div id="partsGrid" className={gridClassname}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            getRowNodeId={this.getRowNodeId}
            rowData={rowData}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={false}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            statusBar={this.state.statusBar}
            components={this.state.components}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // rowHeight={30}
            onFilterChanged={this.onFilterChanged}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.handleGridSizeChanged}
            onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            isRowSelectable={this.state.isRowSelectable}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            domLayout={this.state.domLayout}
            // popupParent={this.state.popupParent}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default PartsGrid;

PartsGrid.propTypes = {
  disableAllParts: PropTypes.bool,
  selectedParts: PropTypes.array,
  rowData: PropTypes.array,
  naNrNssParts: PropTypes.array,
  additionalColumns: PropTypes.array,
  fluidHasQualifiers: PropTypes.bool,
  includeInspectColumns: PropTypes.bool,
  onSelectRow: PropTypes.func
};
PartsGrid.defaultProps = {
  disableAllParts: false,
  selectedParts: [],
  rowData: [],
  naNrNssParts: [],
  additionalColumns: [],
  fluidHasQualifiers: false,
  includeInspectColumns: true,
  onSelectRow: () => {
    // default empty function
  }
};

// let preselectRowScheduled = false;
const ExcludedParts = ["NA", "NR", "NSS"];
const SpecialPartStatus = ["Discontinued", "Superseded"];

function qtyFormatter(params) {
  if (!params || !params.data) {
    return "";
  }
  const { quantity, units } = params.data;
  let label = "";
  if (params.data.hasOwnProperty("motorPartName")) {
    label += toEmptyStringIfUndefined(quantity);
  }
  if (params.data.hasOwnProperty("motorFluidName")) {
    if (toEmptyStringIfUndefined(quantity) !== "" && quantity !== "NS") {
      label += quantity + " " + toEmptyStringIfUndefined(units);
    }
  }
  return label;
}
function relFormatter(params) {
  if (!params || !params.data) {
    return "";
  }
  if (params.data.relationship === "Related/Optional") {
    return "Optional";
  }
  return params.data.relationship;
}
function renderNotes(notes) {
  if (notes && Array.isArray(notes) && notes.length !== 0) {
    if (notes.length === 1) {
      return <div>{notes[0]}</div>;
    } else {
      const list = notes.map((note, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`key${i}`}>{note}</li>
      ));
      return <ul>{list}</ul>;
    }
  }
  return "";
}
function noteCellRenderer(params) {
  // const notes = params.value;
  const { id, notes } = params.data;
  const label = renderNotes(notes);
  return label === "" ? (
    ""
  ) : (
    <div className="ops-notes">
      <Tooltip htmlId={id} tooltipContent={label} className="list-tooltip">
        <IconInfoOutline className="info" />
      </Tooltip>
    </div>
  );
}
function descriptionCellRenderer(params) {
  const { description, motorPartName, notes } = params.data;
  if (motorPartName) {
    // const noteList = notes ? notes.filter((note, i) => i < 2) : [];
    const noteList = notes ? notes : [];
    const badges = noteList.map((note, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Badge htmlId={"noteBadge_" + i} color="gray" key={"key_" + i}>
        {note}
      </Badge>
    ));
    return (
      <div className="ops-part-description">
        <span className="ops-description">{description}</span>
        {badges}
      </div>
    );
  }
  return description;
}
function partNumberCellRenderer(params) {
  // const oePartNumber = params.value;
  const { id, oePartNumber, status, supersessionHistory } = params.data;
  let lookupIcon = "";

  if (supersessionHistory && supersessionHistory.length !== 0) {
    const popoverContent = renderSupersededParts(params, supersessionHistory);
    const keyId = `supersession_${id}`;
    lookupIcon = (
      <div id={`id_${id}`}>
        <Tooltip
          htmlId={keyId}
          tooltipContent="Click to show previous superseded part numbers."
        >
          <span className="parts-instock">
            <Popover
              htmlId="supersededPartsId"
              trigger={["click", "outsideClick"]}
              className="ops-superseded-parts-popover"
              popoverContent={popoverContent}
              position="top"
            >
              <i
                style={{ width: "24px", textAlign: "center" }}
                className="fa fa-history"
              />
            </Popover>
          </span>
        </Tooltip>
      </div>
    );
  }
  if (status && SpecialPartStatus.includes(status)) {
    const keyId = `status_alert_${id}`;
    const specialPartNumber = (
      <div className="ops-special-part-number">
        {" "}
        {oePartNumber}{" "}
        <Tooltip htmlId={keyId} tooltipContent={status}>
          <IconWarning className="ops-part-number-warn" />
        </Tooltip>
        {lookupIcon}
      </div>
    );
    // console.log(specialPartNumber);
    return specialPartNumber;
  } else {
    const specialPartNumber = (
      <div className="ops-special-part-number">
        {" "}
        {oePartNumber}
        <span />
        {lookupIcon}
      </div>
    );
    // console.log(specialPartNumber);
    return specialPartNumber;
  }
}
function renderSupersededParts(params, parts) {
  const { api, data } = params;
  const { id, price } = data;
  const htmlId = `supersededPartsTable_${id}`;
  return (
    <SimpleTable htmlId={htmlId} striped={false}>
      <thead>
        <tr>
          <th>Supersession History</th>
        </tr>
        <tr>
          <th>Select a previous part number to use it instead</th>
        </tr>
        <tr>
          <th scope="col">Part Number</th>
          <th scope="col">Status</th>
          <th scope="col">MSRP/Item</th>
          <th scope="col">DMS Price</th>
          <th scope="col">Qty on Hand</th>
        </tr>
      </thead>
      <tbody>
        {parts.map((d, index) => {
          const { oePartNumber, status } = d;
          const key = `key_${index}`;
          return (
            <tr
              className="parts-supersession-row"
              key={key}
              onClick={event => {
                // console.log("row", index);
                data.oePartNumber = parts[index].oePartNumber;
                data.status = parts[index].status;
                api.refreshCells({ force: true });
                const divEl = document.querySelector(`#id_${id}`);
                if (divEl) {
                  divEl.click();
                }
              }}
            >
              <th scope="row">{oePartNumber}</th>
              <td>{status}</td>
              <td>{price}</td>
              <td>-</td>
              <td>0</td>
            </tr>
          );
        })}
      </tbody>
    </SimpleTable>
  );
}
// function instockCellRenderer(params) {
//   const { id, supersededFluids, supersededParts } = params.data;
//   const parts = supersededParts ? supersededParts : supersededFluids;
//   if (parts && Array.isArray(parts) && parts.length !== 0) {
//     const popoverContent = renderSupersededParts(params, parts); // renderSupersededPopover(parts);
//     const iconHtml = (
//       <div id={`id_${id}`} className="parts-instock has-superseded-parts">
//         <Popover
//           htmlId="supersededPartsId"
//           trigger={["click", "outsideClick"]}
//           className="ops-superseded-parts-popover"
//           popoverContent={popoverContent}
//           position="top"
//         >
//           <i
//             className="far fa-check-circle fa-lg"
//             title="Click to show previous superseded part numbers."
//           />
//         </Popover>
//       </div>
//     );
//     return iconHtml;
//   }
//   return (
//     <span className="parts-instock">
//       <i className="far fa-check-circle fa-lg" />
//     </span>
//   );
// }

/* eslint-enable no-console */
