/* eslint-disable no-console */
import { URL } from "./regex";
import isDate from "date-fns/isDate";
import format from "date-fns/format";

// eslint-disable-next-line no-unused-vars
function test() {
  // eslint-disable-next-line no-console
  console.log("testing ...");
}

export const isUrl = str => URL.test(str);
/**
 *
 * @param {string} str
 * @returns {boolean}
 */
export const isEmptyString = str => {
  return (
    (typeof str === "string" && !str.trim()) ||
    typeof str === "undefined" ||
    str === null
  );
};
/**
 *
 * @param {string} str
 * @returns {string}
 */
export const camelCaseToLowerCaseUnderscore = str =>
  str
    .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
    .toLowerCase()
    .split(" ")
    .join("_");
/**
 *
 * @param {string} text
 * @returns {string}
 */
export const textToHTML = text => {
  return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
};
/**
 *
 * @param {Date} date
 * @returns {string}
 */
export const toISODate = date => {
  // eslint-disable-next-line valid-typeof
  if (typeof date === "Date" && isDate(date)) {
    return format(date, "YYYY-MM-DD");
  }
  return null;
};
/**
 *
 * @param {Date} date
 * @returns {string}
 */
export const toISOTime = date => {
  if (isDate(date)) {
    return format(date, "HH:mm:ss");
  }
  return null;
};
/**
 *
 * @param {string} text
 * @returns {string}
 */
export const splitCamelCase = text => {
  return text.replace(/([a-z](?=[A-Z]))/g, "$1");
};
/**
 *
 * @param {string} text
 * @returns {string}
 */
export const specialCharacters = text => {
  return text.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
};
/**
 *
 * @param {array} daysOfWeekOptions
 * @returns {array}
 */
export const getDaysOfWeekString = daysOfWeekOptions => {
  const daysOfWeekList = ["0", "0", "0", "0", "0", "0", "0"];
  daysOfWeekOptions.forEach(v => {
    daysOfWeekList[Number(v.value)] = "1";
  });
  const daysOfWeekString = daysOfWeekList.join("");
  return daysOfWeekString;
};

/**
 * This function returns individual parameter value for a key used in window query str
 * @param {string} key
 * @returns
 */
export function getUrlParameter(key) {
  // eslint-disable-next-line no-useless-escape
  const name = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
/**
 *
 * @param {string} value
 * @returns {string}
 */
export function toEmptyStringIfUndefined(value) {
  return value === undefined || value === null ? "" : value.toString();
}
/**
 *
 * @param {string} value
 * @returns
 */
export function toNumberZeroIfUndefined(value) {
  return value === undefined || value === null ? "0" : value.toString();
}
/**
 *
 * @param {number} value
 * @returns {float}
 */
export function toTwoDecimals(value) {
  let numVal = NaN;
  if (typeof value === "number") {
    numVal = value;
  } else if (typeof value === "string") {
    numVal =
      value === "" || value === null || value === undefined
        ? NaN
        : parseFloat(value);
  }
  return isNaN(numVal)
    ? ""
    : parseFloat(numVal)
        .toFixed(2)
        .toString();
}
/**
 *
 * @param {string} oldVal
 * @param {string} newVal
 * @returns {boolean}
 */
export function isDifferentFloatingValue(oldVal, newVal) {
  const oldValue = toTwoDecimals(oldVal);
  const newValue = toTwoDecimals(newVal);
  if (oldValue !== newValue) {
    return true;
  }
  return false;
}
/**
 *
 * @param {string} newVal
 * @param {string} oldVal
 * @returns {boolean}
 */
export function isDifferentValue(newVal, oldVal) {
  // console.log("Field compare new vs old", newVal, oldVal);
  const newValue = toEmptyStringIfUndefined(newVal);
  const oldValue = toEmptyStringIfUndefined(oldVal);
  return newValue !== oldValue;
}
/**
 *
 * @param {string} newVal
 * @param {string} oldVal
 * @returns {boolean}
 */
export function isSameValue(newVal, oldVal) {
  return !isDifferentValue(newVal, oldVal);
}
/**
 *
 * @param {string} value
 * @returns {string}
 */
export function upperCaseFirstLetter(value) {
  if (typeof value === "string") {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return value;
}
/**
 *
 * @param {string} str
 * @returns {string}
 */
export function toMixedlCase(str) {
  if (!str || str === "") {
    return "";
  }
  const words = str.split(" ");
  const mixedCaseWords = words.map(word => {
    const lowerCase = word.toLowerCase();
    const mixedCase = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
    return mixedCase;
  });
  const make = mixedCaseWords.join(" ");
  return make;
}
/**
 *
 * @param {number} number
 * @returns {string}
 */
export function formatNumberWithThousands(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
/**
 *
 * @param {Date} date
 * @returns {string}
 */
export function formatLocalizedDatetime(date) {
  return format(date, "MMMM D, YYYY h:mm a");
}
/**
 *
 * @param {string} str
 * @returns {string}
 */
export function toAscii(str) {
  const printable = str ? str.replace(/[^\x20-\x7F]+/g, "") : "";
  return printable;
}
/**
 *
 * @param {string} str
 * @returns {string}
 */
export function sanitize(str) {
  const printable = str ? str.replace(/[^\x20-\xFF]+/g, "") : "";
  return printable;
}
/**
 *
 * @param {string} str
 * @returns {string}
 */
export function sanitizeMle(str) {
  // eslint-disable-next-line no-control-regex
  const printable = str ? str.replace(/[^\x09-\x0A\x0D\x20-\xFF]+/g, "") : "";
  if (printable.trim() !== "") {
    return printable;
  }
  return "";
}
/* eslint-enable no-console */
