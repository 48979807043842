/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../../../components/app-context";
import CustomLoadingOverlay from "./../../../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import {
  makeSecureRestApi
  // showBodyMask,
  // hideBodyMask
} from "../../../../../../api/xmmAxios";
import Popover from "@cx/ui/Popover";
import Button from "@cx/ui/Button";
import SearchableSelect from "@cx/ui/SearchableSelect";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import { toast } from "@cx/ui/Toast";
import { AgGridReact } from "ag-grid-react";
import {
  appendToExternalList,
  removeFromExternalList
} from "../../../../../reusable/operation";
import { toEmptyStringIfUndefined } from "../../../../../../commonUtil/utils/string";
import { relationShipList } from "../../../../../../constants/ModuleConstants";

class PartTypes extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.handleAddExternal = this.handleAddExternal.bind(this);
    this.handleDeleteExternal = this.handleDeleteExternal.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.setAutoHeight = this.setAutoHeight.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);
    const localeStrings = context.localeStrings;
    const gridOptions = {
      // other state props
      pageTitle: "Part Types",
      // ag-grid props
      rowData: null, // should be null - fix to skip "No records found" msg on grid load.
      selectableParts: [],
      selectionlist: [],
      newParts: [],
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        // cellClass: "xmm-wrap-cell",
        // autoHeight: true,
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        filter: false,
        filterParams: {
          buttons: ["clear"]
        },
        floatingFilter: false, // true - enable column header filters
        rowGroup: false
      },
      multiSortKey: "ctrl",
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },

      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
        selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
        totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
        totalAndFilteredRows:
          localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
        noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid
      // domLayout: "autoHeight",
      // setAutoHeight: this.setAutoHeight
    };
    this.state = gridOptions;
  }
  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: "Part Type",
        headerClass: "ag-text-header",
        field: "partTypeName",
        tooltipField: "partTypeName",
        minWidth: "300"
      },
      {
        headerName: "Relationship",
        field: "relationship",
        headerClass: "ag-text-header",
        editable: true,
        enableRowGroup: false,
        cellEditorSelector(params) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: relationShipList
            }
          };
        },
        cellClass: "editable-caret-cell"
      }
      // {
      //   headerName: "Required Group",
      //   field: "requiredGroupName",
      //   headerClass: "ag-text-header"
      // }
    ];
    return baseCols;
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    setTimeout(() => {
      this.setAutoHeight();
    }, 0);
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };
  setAutoHeight = () => {
    this.sizeToFit();
    const { domLayout } = this.state;
    const rowData = this.props.rowRecord.cntExternalParts;
    if (rowData) {
      // const newDomLayout = rowData < MaxGridRows ? "autoHeight" : "normal";
      const newDomLayout = "normal";
      if (domLayout !== newDomLayout) {
        this.gridApi.setDomLayout(newDomLayout);
        this.setState({ domLayout: newDomLayout });
      }
    }
  };
  handleGridSizeChanged = () => {
    this.sizeToFit();
    this.setAutoHeight();
  };
  onCellValueChanged(params) {
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      this.handleUpdateExternal(params);
    }
  }
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  getRowNodeId(data) {
    return data.id;
  }
  loadGridData() {
    // Point to static Json file
    // const restUrl = window.origin + "/data/getExternalOps.json";
    const restUrl = "/opsadmin/rest-db/getValues/externalParts";
    const { rowRecord } = this.props;
    const { operationId } = rowRecord;
    const params = { operationId };

    // showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params
      },
      data => {
        if (data) {
          console.log(data);
          // hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        // hideBodyMask();
      }
    );
  }
  updateState(data) {
    const parts = Array.isArray(data) ? data : [];
    this.setState(
      {
        rowData: parts
      },
      () => {
        this.sizeToFit();
        this.initializeParts();
        this.setAutoHeight();
      }
    );
  }
  initializeParts() {
    const { globalOpsData } = this.context;
    const { externalPartOptions } = globalOpsData;
    if (!externalPartOptions || externalPartOptions.length === 0) {
      // get all external ops that can be selected by user in Add case
      this.getAllParts();
    } else {
      this.setUpSelectableParts(externalPartOptions);
    }
  }
  getAllParts() {
    const restUrl = "/opsadmin/rest-db/getValues/partOptions";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data) {
          const { globalOpsData } = this.context;
          globalOpsData.externalPartOptions = data;
          this.setUpSelectableParts(globalOpsData.externalPartOptions);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching parts.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  setUpSelectableParts(externalPartOptions) {
    const { rowData } = this.state;
    const selectedExternalPartIDs = rowData.map(o => o.externalPartTypeId);
    const selectableParts = externalPartOptions.filter(
      op => !selectedExternalPartIDs.includes(op.value)
    );
    this.setState({ selectableParts }, () => {
      this.setAutoHeight();
    });
  }
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "partType",
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  onSelect = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const newParts = value;
    this.setState({ newParts });
  };
  handleAddExternal(e) {
    const { rowRecord } = this.props;
    const { operationId } = rowRecord;
    const { newParts } = this.state;
    const totalCount = newParts.length;
    let addCount = 0;
    const newPartIds = [];
    const newPartLabels = newParts.map(f => f.label);
    newParts.forEach(op => {
      const externalPartTypeId = op.value;
      const data = { externalPartTypeId, operationId };
      const restUrl = "/opsadmin/rest-db/addTableData/externalParts";
      makeSecureRestApi(
        {
          url: restUrl,
          method: "post",
          data
        },
        parts => {
          addCount++;
          if (parts && parts.length !== 0) {
            const part = parts[0];
            part.id = part.operationExternalPartId;
            part.partTypeName = op.label;
            newPartIds.push(part.externalPartTypeId);

            const res = this.gridApi.applyTransaction({
              add: [part]
            });
            // console.log(res);
            const rowNode = this.gridApi.getRowNode(part.id);
            rowNode.setSelected(false);
            this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");

            // rowData.push(extOp);
            // this.setState({ rowData });
          }
          if (addCount >= totalCount) {
            // remove added external ops from selectableExternalOps
            const { selectableParts } = this.state;

            const updatedList = selectableParts.filter(
              op => !newPartIds.includes(op.value)
            );
            newParts.length = 0;
            this.setState({ selectableParts: updatedList });
            appendToExternalList(
              rowRecord,
              "externalPartsList",
              newPartLabels,
              "cntExternalParts"
            );
            const { updateGridAfterSave } = this.props;
            updateGridAfterSave(rowRecord, "edit");
            // this.gridApi.setRowData(rowData);
            closeAddPartsPopover();
          }
        },
        error => {
          const msg = error["message"]
            ? error.message
            : "There was an error adding external operation.";
          toast.error(msg, {
            closeOnClick: true
          });
        }
      );
    });
  }
  handleUpdateExternal(params) {
    const data = { id: params.data.id, [params.column.colId]: params.value };
    const restUrl = "/opsadmin/rest-db/updateTableData/externalParts";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      response => {
        //
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error adding external operation.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  handleDeleteExternal(e) {
    const { selectionlist } = this.state;
    if (selectionlist && selectionlist.length !== 0) {
      const restUrl = "/opsadmin/rest-db/deleteTableRow/externalParts";
      const { partTypeName, id } = selectionlist[0];
      const data = { id };
      makeSecureRestApi(
        {
          url: restUrl,
          method: "post",
          data
        },
        result => {
          console.log(result);
          const res = this.gridApi.applyTransaction({
            remove: selectionlist
          });
          console.log(res);
          selectionlist.length = 0;
          this.gridApi.deselectAll();
          const { rowRecord, updateGridAfterSave } = this.props;
          removeFromExternalList(
            rowRecord,
            "externalPartsList",
            partTypeName,
            "cntExternalParts"
          );
          updateGridAfterSave(rowRecord, "edit");
        },
        error => {
          const msg = error["message"]
            ? error.message
            : "There was an error deleting part.";
          toast.error(msg, {
            closeOnClick: true
          });
        }
      );
    }
  }
  render() {
    const { selectionlist, selectableParts, newParts } = this.state;
    const addWidget = (
      <form autoComplete="off">
        <SearchableSelect
          className="xmm-scrollable-select"
          htmlId="externalOperationsSearchable"
          label="Parts"
          displayLabel={true}
          name="newParts"
          onChange={this.onSelect}
          value={newParts}
          options={selectableParts}
          maxHeight={500}
          maxResults={200}
          paginate={true}
        />
        <Button
          htmlId="continueBtn"
          // text="Continue to set details"
          size="small"
          block
          onClick={this.handleAddExternal}
          buttonStyle="secondary"
        >
          {this.context.localeStrings["xmmadmin.portal.common.addParts_button"]}
        </Button>
      </form>
    );
    // const { rowData } = this.state;
    // const gridClassName =
    //   rowData && rowData.length < MaxGridRows
    //     ? "ag-grid-container xmm-auto-height ag-theme-balham"
    //     : "ag-grid-container xmm-small-grid ag-theme-balham";
    const gridClassName = "ag-grid-container xmm-small-grid ag-theme-balham";
    const tooltipInfo = (
      <div>
        <b>
          {
            this.context.localeStrings[
              "xmmadmin.portal.external_pricing.relationship_primary"
            ]
          }
        </b>
        <p className="xmm-popover-text">
          {
            this.context.localeStrings[
              "xmmadmin.portal.external_pricing.relationship_primary_tip"
            ]
          }
        </p>
        <b>
          {
            this.context.localeStrings[
              "xmmadmin.portal.external_pricing.relationship_recommended"
            ]
          }
        </b>
        <p className="xmm-popover-text">
          {
            this.context.localeStrings[
              "xmmadmin.portal.external_pricing.relationship_recommended_tip"
            ]
          }
        </p>
        <b>
          {
            this.context.localeStrings[
              "xmmadmin.portal.external_pricing.relationship_optional"
            ]
          }
        </b>
        <p className="xmm-popover-text">
          {
            this.context.localeStrings[
              "xmmadmin.portal.external_pricing.relationship_related_tip"
            ]
          }
        </p>
      </div>
    );
    const tooltipHeader = (
      <Popover popoverContent={tooltipInfo} position="right">
        <IconInfoOutline className="info-blue" />
      </Popover>
    );
    return (
      <React.Fragment>
        <div className="xmm-external-pricing-grid">
          <div className="slider-title">
            <h4>
              {this.state.pageTitle} {tooltipHeader}
            </h4>
            <div className="btn-toolbar">
              <Popover
                htmlId="popoverAdd"
                className="xmm-add-external-popover"
                popoverContent={addWidget}
                position="left"
                trigger={["click"]}
                // trigger={["click", "outsideClick"]}
              >
                <Button htmlId="addPartBtn" buttonStyle="primary">
                  {this.context.localeStrings["xmm.portal.common.add_button"]}
                </Button>
              </Popover>
              <Button
                htmlId="deletePartAction"
                buttonStyle="secondary"
                disabled={selectionlist.length === 0}
                onClick={this.handleDeleteExternal}
              >
                {this.context.localeStrings["xmm.portal.common.delete_button"]}
              </Button>
            </div>
          </div>
          <div id="grid-wrapper">
            <div id="partTypeGrid" className={gridClassName}>
              <AgGridReact
                localeText={this.state.localeText}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                suppressMenuHide={false}
                suppressContextMenu={true}
                getRowNodeId={this.getRowNodeId}
                rowData={this.state.rowData}
                rowSelection="single"
                singleClickEdit={true}
                stopEditingWhenGridLosesFocus={true}
                animateRows={true}
                statusBar={this.state.statusBar}
                components={this.state.components}
                multiSortKey={this.state.multiSortKey}
                enableRangeSelection={false}
                enableCellTextSelection={true}
                enableBrowserTooltips={true}
                // rowHeight={30}
                onCellValueChanged={this.onCellValueChanged}
                onFilterChanged={this.onFilterChanged}
                onGridReady={this.onGridReady}
                onGridSizeChanged={this.handleGridSizeChanged}
                onSelectionChanged={this.handleSelectionChanged}
                frameworkComponents={this.state.frameworkComponents}
                domLayout={this.state.domLayout}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default PartTypes;

PartTypes.propTypes = {
  rowRecord: PropTypes.object,
  updateGridAfterSave: PropTypes.func
};
// const MaxGridRows = 10;
// Simulate click function
function closeAddPartsPopover() {
  document.querySelector("#addPartBtn").click();
}
/* eslint-enable no-console */
