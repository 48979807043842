/* eslint-disable no-console */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import NumericInput from "@cx/ui/NumericInput";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import TextInput from "@cx/ui/TextInput";
import Button from "@cx/ui/Button";
import RadioButtonList from "@cx/ui/RadioButtonList";
import { PreviewContext } from "./../../preview-ops-context";
import ToggleSwitch from "@cx/ui/ToggleSwitch";

function SearchPane(props) {
  const context = useContext(PreviewContext);
  const { populateContext } = useContext(PreviewContext); // context method to update state of PreviewPane
  // console.log("[SearchPane.js] context", context);
  // derive initial state from props
  const [vin, setVin] = useState(context.vin);
  const [dealerCode, setDealerCode] = useState(context.dealerCode);
  const [dealerFlag, setDealerFlag] = useState(context.dealerFlag); // "0"
  const [mileage, setMileage] = useState(context.mileage);
  const [errors, setErrors] = useState({
    vin: "",
    operations: "",
    dealerCode: ""
  }); // used as local state

  // implement getDerivedStateFromProps logic here
  if (props.errors !== errors) {
    setErrors(props.errors);
  }
  const updateContext = (key, value) => {
    populateContext(key, value);
    // console.log("update context", key, value);
  };

  // derive updated state from props
  React.useEffect(() => {
    console.log(`VIN's length: ${vin.length}`);
  }, [vin]);

  const onSearch = event => {
    event.preventDefault();
    updateContext("dealerCode", dealerCode);
    const { getAllOperations } = props;
    const callback = () => {
      getAllOperations(dealerFlag === "1" ? dealerCode : "", mileage);
    };
    if (!mileage) {
      setMileage(getDefaultMileage());
    }
    setTimeout(callback, 100);
  };

  const onMileageInput = (event, isValid, domEvent) => {
    const { value } = event.target;
    if (mileage !== value) {
      setMileage(value);
      // context.mileage = value;
    }
  };

  function validateVin(value) {
    if (value.length === 0 || value.length === 17) {
      errors.vin = "";
    } else {
      errors.vin = "17 Characters Required";
    }
    setErrors(errors);
  }
  function onVinBlur(cxEvent, isValid, domEvent) {
    const { value } = cxEvent.target;
    validateVin(value);
  }
  function onVinChange(cxEvent, isValid, domEvent) {
    const { value } = cxEvent.target;
    // skip non-alphanumeric characters
    if (!isAlphaNumeric(value)) {
      return;
    }
    if (value.length < vin.length || value.length === 17) {
      validateVin(value);
    }
    // update vin as user types
    setVin(value);
  }
  // event called upon Decode button click
  function onVinDecode(event) {
    event.preventDefault();
    if (vin.length !== 17) {
      errors.vin = "17 Characters Required";
      setErrors(errors);
    } else {
      props.decodeVin(vin);
    }
  }

  function onDealerFlagChange(cxEvent, isValid, domEvent) {
    const { value } = cxEvent.target;
    setDealerFlag(value);
    updateContext("dealerFlag", value);
    if (value !== "1") {
      setDealerCode("");
      setMileage("");
      updateContext("dealerCode", "");
    }
  }

  function getDefaultMileage() {
    const { vehicleDesc } = context;
    if (vehicleDesc) {
      const { year } = vehicleDesc;
      if (year) {
        const now = new Date();
        const years = now.getFullYear() - year;
        return years * 10000;
      }
    }
    return "";
  }

  // function updateMileage() {
  //   const { vehicleDesc } = context;
  //   if (vehicleDesc) {
  //     const { year } = vehicleDesc;
  //     if (year) {
  //       const now = new Date();
  //       const years = now.getFullYear() - year;
  //       setMileage(years * 10000);
  //     }
  //   }
  // }

  function onDealerCodeChange(cxEvent, isValid, domEvent) {
    const { value } = cxEvent.target;
    setDealerCode(value.toUpperCase());
  }

  function isAlphaNumeric(value) {
    const regex = /^[a-zA-Z0-9]([a-zA-Z0-9*]){0,}$/i;
    if (value && !regex.test(value)) {
      return false;
    }
    return true;
  }

  function changeYMM(e) {
    const value = e.target.value === "checked" ? true : false;
    context.updateYMM(value);
  }

  return (
    <React.Fragment>
      <Row className="ops-form-section">
        <Col xs={3} md={3}>
          <TextInput
            displayLabel
            htmlId="VinNumberInput"
            label="VIN"
            name="vin"
            value={vin}
            minLength={0}
            maxLength={17}
            error={errors.vin}
            onBlur={onVinBlur}
            onChange={onVinChange}
            appendChild={
              <Button buttonStyle="default" onClick={onVinDecode}>
                Decode
              </Button>
            }
          />
        </Col>
        <Col xs={3} md={3}>
          <RadioButtonList
            inline
            htmlId="radioButtonList"
            name="radioButtonList"
            displayLabel={false}
            value={dealerFlag}
            options={[
              {
                value: "0",
                label: "Global",
                disabled: !props.decodeVinOk
              },
              {
                value: "1",
                label: "Dealer"
                // disabled: !p÷rops.decodeVinOk
              }
            ]}
            onChange={onDealerFlagChange}
          />
          <TextInput
            displayLabel={false}
            htmlId="VinDealerInput"
            name="dealerInput"
            disabled={dealerFlag !== "1"}
            error={props.errors.dealerCode}
            value={dealerCode}
            onChange={onDealerCodeChange}
          />
        </Col>
        <Col xs={2} md={2}>
          <NumericInput
            htmlId="mileage"
            name="mileage"
            value={mileage}
            // style={{ width: "80px" }}
            disabled={dealerFlag !== "1"}
            displayLabel={false}
            layout="horizontal"
            autoInsertCommas
            onChange={onMileageInput}
            inputSuffix={"miles"}
            maxLength={6}
            minLength={0}
            // error={errors.annualMileage}
          />
        </Col>
        <Col>
          <Button
            htmlId="SearchBtn"
            buttonStyle="primary"
            disabled={
              (dealerFlag === "0" && !props.decodeVinOk) ||
              (dealerFlag === "1" && dealerCode.length === 0)
            }
            onClick={onSearch}
          >
            Get Operations
          </Button>
        </Col>
        <Col xs={2} md={2}>
          <ToggleSwitch
            className="inputymm"
            label="Filter by YMM"
            htmlId="ToggleSwitch"
            defaultChecked={true}
            onClick={e => changeYMM(e)}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default SearchPane;

SearchPane.propTypes = {
  search: PropTypes.object,
  decodeVin: PropTypes.func,
  decodeVinOk: PropTypes.bool,
  getAllOperations: PropTypes.func,
  errors: PropTypes.object
};
