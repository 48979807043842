/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React from "react";
import { toast } from "@cx/ui/Toast";

// This Speed up calls to hasOwnProperty
const doesOwnProperty = Object.prototype.hasOwnProperty;

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
// Example:
// sleep(300).then(() => {
// console.log("Timeout started");
// });

export const DisplayState = props => (
  <div style={{ margin: "1rem 0" }}>
    <pre className="pre-style">
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

export function findRecord(list, key, id) {
  // returns array of matched items
  const found = list.filter(function(obj) {
    return obj[key] === id;
  });
  if (found.length !== 1) {
    toast.error("Cannot find the operation with this id: " + id);
    return null;
  }
  return found[0];
}

export function isDifferentValue(newVal, oldVal) {
  // console.log("Field compare new vs old", newVal, oldVal);
  const newValue = toEmptyStringIfUndefined(newVal);
  const oldValue = toEmptyStringIfUndefined(oldVal);
  return newValue !== oldValue;
}

export function isSameValue(newVal, oldVal) {
  return !isDifferentValue(newVal, oldVal);
}

export function toEmptyStringIfUndefined(value) {
  return value === undefined || value === null ? "" : value.toString();
}

function internalFormatPrice(value) {
  const price = Math.round(value * 100) / 100;
  if (!isNaN(price) && price >= 0) {
    return "$".concat(price.toFixed(2));
  }
  return "-";
}

export const priceFormatter = params => {
  if (
    params.value === "" ||
    params.value === undefined ||
    params.value === null
  ) {
    return "-";
  }
  return internalFormatPrice(params.value);
};

export const isEmpty = obj => {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (doesOwnProperty.call(obj, key)) return false;
  }
  return true;
};

/**
 * Returns true if the passed value is empty, false otherwise. The value is deemed to be empty if it is either:
 *
 * - `null`
 * - `undefined`
 * - a zero-length array
 * - a zero-length string (Unless the `allowEmptyString` parameter is set to `true`)
 *
 * @param {Object} value The value to test
 * @param {Boolean} allowEmptyString (optional) true to allow empty strings (defaults to false)
 * @return {Boolean}
 * @markdown
 */
export const doesEmpty = (value, allowEmptyString) => {
  return (
    value === null ||
    value === undefined ||
    (!allowEmptyString ? value === "" : false) ||
    (isArrayExist(value) && value.length === 0)
  );
};

/**
 * Returns true if the passed value is a JavaScript Array, false otherwise.
 *
 * @param {Object} target The target to test
 * @return {Boolean}
 * @method
 */
export const isArrayExist = value => {
  return Object.prototype.toString.call(value) === "[object Array]";
};

export const isArrayList = val => {
  return Array.isArray(val);
};

export const defaultToZeroIfNullOrEmpty = value => {
  return !value ? 0 : Number(value);
};

export function convertMinutesToHours(timeParam, decimals) {
  /*
   * example:
   * 8.4/60 = 0.14
   * 0.14 * 100 = 14.000000000000002
   * 14.000000000000002 toFixed(1) becomes 14.0
   * Math.ceil(14.0) becomes 14
   * 14/100 = 0.14
   * 0.14.toFixed(2) becomes 0.14
   * 0.14 is returned
   *
   * */
  let time = 0;
  if (timeParam) {
    time = timeParam;
    time /= 60;
    time *= 100;
    /* just one decimal is enough to take ceiling */
    time = time.toFixed(1);
    time = Math.ceil(time);
    time /= 100;
    time = time.toFixed(!decimals ? 2 : decimals);
    return time;
  }
  return 0;
}
