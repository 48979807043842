/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
// import { toast } from "@cx/ui/Toast";
// import { isArrayExist, isEmpty } from "../../../commonUtil/utils/object";
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import CustomTooltip from "../../../commonUtil/components/reusable/CustomToolTip";
import { AppContext } from "../../../components/app-context";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SelectInput from "@cx/ui/SelectInput";
import SearchableSelect from "@cx/ui/SearchableSelect";
import { xlate } from "../../../commonUtil/i18n/locales";
import { loadAgGridLocale } from "../../../i18n/LocaleSender";
import { applyCustomKeyNavigation } from "../../../commonUtil/utils/keyNavigation";
import {
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../commonUtil/utils/string";

import {
  blankValueFormatter,
  priceFormatter
} from "../../../commonUtil/utils/formatter";

class PackagesGrid extends Component {
  static contextType = AppContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.showGrid !== prevState.showGrid ||
      nextProps.menuTypes !== prevState.menuTypes
    ) {
      return {
        showGrid: nextProps.showGrid,
        // packages: nextProps.packages,
        menuType:
          nextProps.menuTypes && nextProps.menuTypes.length !== 0 ? "ALL" : "",
        menuTypes: nextProps.menuTypes
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props, context);
    const localeStrings = context.localeStrings;
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    // Bind other external actions
    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.onMenuTypeSelect = this.onMenuTypeSelect.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.initializeLocaleValues();
    const { menuTypes } = props;
    const gridOptions = {
      selectionlist: [],
      packageType: "ALL",
      searchKey: context.packagesGrid.searchKey,
      packagesGrid: context.packagesGrid,
      menuType: menuTypes && menuTypes.length !== 0 ? "ALL" : "",
      showGrid: props.showGrid,
      // packages: props.packages,
      menuTypes: props.menuTypes,
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        floatingFilter: true, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false,
        enableRowGroup: false,
        filter: true,
        suppressMenu: true,
        sortingOrder: ["desc", "asc", null],
        minWidth: 80,
        autoHeight: true,
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      rowData: null,
      multiSortKey: "ctrl",
      frameworkComponents: {
        customTooltip: CustomTooltip,
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.loading_msg"),
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.no_records_msg"),
        // isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: loadAgGridLocale(localeStrings),
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      rowSelection: "multiple", // allows multiple row selections with check column
      isRowSelectable(rowNode) {
        return true; // to see checkbox
      },
      columnTypes: {
        numberColumn: {
          maxWidth: 120,
          // minWidth: 120,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,

            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        },
        noFilterColumn: {
          filter: false,
          editable: false,
          sortable: true,
          suppressMenu: true,
          enableRowGroup: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        hiddenByDefault: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {}
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
  }

  initializeLocaleValues() {
    this.searchLabel = xlate("xmm.portal.common.search_label");
    this.menuTypeLabel = xlate("xmm.portal.common.menutype");
    this.descriptionLabel = xlate("xmm.portal.grid.description");
    this.intervalLabel = xlate("xmm.portal.common.interval");
    this.vehiclesLabel = xlate("xmm.portal.grid.vehicles");
    this.opcodeLabel = xlate("xmm.portal.grid.opcode");
    this.opcodeStatusLabel = xlate("xmm.portal.grid.opcode_status");
    this.priceLabel = xlate("xmm.portal.packages.price_lbl");
    this.schedulingDurationLabel = xlate(
      "xmm.portal.common.schedule_duration_lbl"
    );
    this.packageTypeLabel = xlate("xmm.portal.grid.package_type");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveError = xlate("xmm.portal.errors.save_data_error");
  }

  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "menuTypeDescription",
        sortIndex: 1,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const packagesGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey
      };
      this.setState({
        packagesGrid
      });
      this.context.setPackagesGridState(packagesGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const {
      colState,
      filterState,
      pivotState,
      searchKey
    } = this.state.packagesGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.setState(
        {
          searchKey
        },
        prevState => {
          this.showAllColumns("ALL");
          this.applyTypeFilter("ALL", "override");
          this.gridApi.setQuickFilter(searchKey);
          this.gridColumnApi.setColumnState(colState);
          this.gridColumnApi.setPivotMode(pivotState);
          this.gridApi.setFilterModel(filterState);
          this.assignColumnState(colState);
        }
      );
    }
  }
  onFirstDataRendered(params) {
    this.restoreGridState();
  }
  autoSizeCols() {
    const allColumnIds = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function(column) {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };

  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.id;
  }

  showLoadingOverlay() {
    this.gridApi && this.gridApi.showLoadingOverlay();
  }
  showNoRowsOverlay() {
    this.gridApi && this.gridApi.showNoRowsOverlay();
  }
  hideOverlay() {
    this.gridApi && this.gridApi.hideOverlay();
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }

  onMenuTypeSelect = (cxEvent, isValid) => {
    const { value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    const filterValue = !optionValue ? "ALL" : optionValue.toString();
    console.log("menu select", filterValue, value);
    const { menuType } = this.state;
    if (isDifferentValue(menuType, filterValue)) {
      this.setState(
        {
          menuType: optionValue
        },
        prevState => {
          this.applyTypeFilter(cxEvent, filterValue, "menuTypeId");
          this.sizeToFit();
        }
      );
    }
  };
  /* External filter - filterBy packageType */
  onColumnViewChange = (cxEvent, isValid) => {
    const { value, name } = cxEvent.target;
    const filterValue = !value ? "ALL" : value;
    this.setState(
      {
        [name]: filterValue
      },
      prevState => {
        this.showAllColumns(filterValue);
        this.applyTypeFilter(filterValue, "override");
        this.sizeToFit();
      }
    );
  };
  /* show/hide columns based on package type */
  showAllColumns(filterValue) {
    const cols = ["checked", "menuTypeDescription", "name", "intervalName"];
    const hideCols = ["dmsOpcode", "price", "shopDuration", "packageType"];
    if (this.gridColumnApi) {
      // hide custom columns
      this.gridColumnApi.setColumnsVisible(hideCols, false);
      if (filterValue === "ALL") {
        cols.push("dmsOpcode");
        cols.push("price");
        cols.push("shopDuration");
      } else if (filterValue === "DMS_OPCODE") {
        cols.push("dmsOpcode");
      } else if (filterValue === "PRICE") {
        cols.push("price");
      } else if (filterValue === "SHOP_DURATION") {
        cols.push("shopDuration");
      }
      // console.log("visible cols", cols);
      this.gridColumnApi.setColumnsVisible(cols, true);
    }
  }
  applyTypeFilter = (filterVal, filterType) => {
    if (this.gridApi) {
      const typeFilter = this.gridApi.getFilterInstance("packageType");
      const menuFilter = this.gridApi.getFilterInstance("menuTypeId");
      const model = [];
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        this.gridApi.setFilterModel(null);
      }
      if (filterVal && filterVal === "ALL") {
        typeFilter.setModel(null);
      } else if (filterType === "menuTypeId") {
        model.push(filterVal);
        menuFilter.setModel(model);
      } else {
        model.push(filterVal);
        typeFilter.setModel(model);
      }
      this.gridApi.onFilterChanged();

      // DON'T clear Search box
      document.querySelector("#package-search-box").value = "";
      this.gridApi.setQuickFilter("");
    }
  };

  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#package-search-box").value;
      this.gridApi.setQuickFilter(searchKey);
      this.clearGridSelections();
      this.setState({
        searchKey
      });
    }
  };
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = event => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState(
        {
          selectionlist: selectedRows
        },
        prevState => {
          this.props.gridSelectionsFunc(selectedRows);
        }
      );
    }
  };
  /* Action event to clear column filters */
  clearFilters() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        this.gridApi.setFilterModel(null);
      }
      this.gridApi.onFilterChanged();
      document.querySelector("#package-search-box").value = "";
      this.onSearchBoxChanged();
    }
  }
  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: "left",
        field: "checked",
        type: "actionColumn",
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true, // hide item in sidebar.columns
        maxWidth: 40,
        minWidth: 40,
        width: 40
      },
      {
        headerName: this.menuTypeLabel,
        field: "menuTypeDescription",
        cellClass: "xmm-wrap-cell",
        tooltipField: "menuTypeName",
        tooltipComponentParams: { field: "menuTypeName" },
        tooltipComponent: "customTooltip",
        minWidth: 250,
        maxWidth: 350,
        filter: "agSetColumnFilter",
        filterParams: { buttons: ["clear"] }
      },
      {
        headerName: this.descriptionLabel,
        field: "name",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        autoHeight: true,
        minWidth: 250,
        sortingOrder: ["asc", "desc"],
        valueFormatter: blankValueFormatter,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: this.intervalLabel,
        field: "intervalName",
        cellClass: "xmm-wrap-cell",
        headerClass: "ag-text-header",
        minWidth: 250,
        valueFormatter: blankValueFormatter,
        filter: "agTextColumnFilter",
        filterParams: { buttons: ["clear"] }
      },
      {
        headerName: this.opcodeLabel,
        field: "dmsOpcode",
        headerClass: "ag-text-header",
        valueFormatter: doesnotExistFormatter,
        cellClass: "xmm-wrap-cell non-empty",
        suppressSizeToFit: true,
        minWidth: 120,
        width: 120,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: this.priceLabel,
        field: "price",
        headerClass: "ag-numeric-header",
        type: "numberColumn",
        cellClass: "xmm-grid-price",
        cellStyle: {
          textAlign: "right"
        },
        valueFormatter: priceFormatter,
        suppressSizeToFit: true,
        minWidth: 110,
        width: 110
      },
      {
        headerName: this.schedulingDurationLabel,
        field: "shopDuration",
        minWidth: 110,
        width: 110,
        type: "numberColumn",
        cellClass: "non-empty",
        suppressSizeToFit: true,
        valueFormatter: doesnotExistFormatter
      },
      {
        headerName: "MenuTypeId",
        type: "noFilterColumn",
        hide: true,
        field: "menuTypeId",
        headerClass: "ag-text-header",
        valueFormatter: blankValueFormatter,
        suppressColumnsToolPanel: true,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, buttons: ["clear"] },
        maxWidth: 100,
        minWidth: 100
      },
      {
        headerName: this.packageTypeLabel,
        type: "noFilterColumn",
        hide: true,
        field: "packageType",
        headerClass: "ag-text-header",
        valueFormatter: blankValueFormatter,
        suppressColumnsToolPanel: true,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: true, buttons: ["clear"] },
        maxWidth: 150,
        minWidth: 100
      }
    ];
    return baseCols;
  }
  renderPackageTemplateGrid(showGrid) {
    return showGrid ? (
      <div
        id="packagesGrid"
        className="ag-grid-container ag-theme-balham xmm-push-packages-grid"
      >
        <AgGridReact
          localeText={this.state.localeText}
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          suppressMenuHide={false}
          suppressContextMenu={true}
          rowData={this.props.packages}
          animateRows={true}
          onGridReady={this.onGridReady}
          frameworkComponents={this.state.frameworkComponents}
          loadingOverlayComponent={this.state.loadingOverlayComponent}
          loadingOverlayComponentParams={
            this.state.loadingOverlayComponentParams
          }
          noRowsOverlayComponent={this.state.noRowsOverlayComponent}
          noRowsOverlayComponentParams={this.state.noRowsOverlayComponentParams}
          statusBar={this.state.statusBar}
          enableRangeSelection={false}
          enableCellTextSelection={true}
          enableBrowserTooltips={false}
          sideBar={this.state.sideBar}
          columnTypes={this.state.columnTypes}
          multiSortKey={this.state.multiSortKey}
          onSelectionChanged={this.handleSelectionChanged}
          rowSelection={this.state.rowSelection}
          rowDeselection={true}
          isRowSelectable={this.state.isRowSelectable}
          rowHeight={50}
          onFilterChanged={this.onFilterChanged}
          onFirstDataRendered={this.onFirstDataRendered}
        />
      </div>
    ) : (
      ""
    );
  }
  render() {
    const gridWidget = this.renderPackageTemplateGrid(this.state.showGrid);
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={12}>
            <div className="content-header">
              <div className="xmm-form-header">
                <SearchableSelect
                  htmlId="menuTypeSelect"
                  className="xmm-menu-type-select"
                  label="MenuType"
                  displayLabel={false}
                  placeholder="All Menus"
                  name="menuTypeId"
                  displayDeselectOption={true}
                  disabled={this.state.menuTypes.length === 0}
                  enableMultiSelect={false}
                  onChange={this.onMenuTypeSelect}
                  value={this.state.menuType}
                  options={this.state.menuTypes}
                />
                <SelectInput
                  htmlId="packageTypeSelect"
                  className="xmm-filter-type-select"
                  placeholder="Select"
                  displayLabel={false}
                  displayDeselectOption={false}
                  name="packageType"
                  onChange={this.onColumnViewChange}
                  label="View"
                  value={this.state.packageType}
                  options={[
                    {
                      value: "ALL",
                      label: this.context.localeStrings[
                        "xmm.portal.packages.all_types_lbl"
                      ]
                    },
                    {
                      value: "PRICE",
                      label: this.context.localeStrings[
                        "xmm.portal.packages.price_lbl"
                      ]
                    },
                    {
                      value: "DMS_OPCODE",
                      label: this.context.localeStrings[
                        "xmm.portal.common.opcode"
                      ]
                    },
                    {
                      value: "SHOP_DURATION",
                      label: this.context.localeStrings[
                        "xmm.portal.common.schedule_duration_lbl"
                      ]
                    }
                  ]}
                />
                <div className="xmm-input-search">
                  <input
                    type="text"
                    id="package-search-box"
                    className="xmm-input"
                    placeholder={this.searchLabel}
                    onChange={this.onSearchBoxChanged}
                    value={this.state.searchKey}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            {gridWidget}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default PackagesGrid;

PackagesGrid.propTypes = {
  showGrid: PropTypes.bool,
  packages: PropTypes.array,
  menuTypes: PropTypes.array,
  gridSelectionsFunc: PropTypes.func
};

function doesnotExistFormatter(params) {
  const val = toEmptyStringIfUndefined(params.value);
  if (val) {
    return val;
  } else {
    return "";
  }
}

/* eslint-enable no-console */
